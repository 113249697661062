export default {
  'news.router.name.1': 'Broadcasts Schedule',
  'news.router.name.2': 'Location Broadcast Schedule',
  'news.router.nameBreadcrumb': 'Broadcast Schedule Management',
  'news.tabs.label.1': 'Location Broadcast Schedule',
  'news.tabs.label.2': 'Sub-location broadcast schedule',
  'news.title': 'Location Broadcast Schedule',
  'news.title.media': 'List of used broadcast schedule',
  'news.approval.title': 'Confirm Broadcast Schedule Approval',
  'news.approval.content': 'The selected broadcast schedule will be approved.',
  'news.create.continue': 'Create Next Schedule',
  'news.lastTime.title': 'Cannot create broadcast schedule',
  'news.lastTime.content': 'Cannot create follow-up broadcast schedule because the day is over',
  'news.plus.square': 'Copy Schedule',
  'news.icon.pause': 'Paused',
  'news.pause.title': 'Confirm Pause Broadcast Schedule',
  'news.pause.content':
    'The broadcast schedule is being broadcast on the device. Are you sure you want to pause the schedule?',
  'news.icon.continue': 'Continue Broadcasting',
  'news.continue.title': 'Confirm Continue News Broadcast',
  'news.continue.content':
    'The broadcast schedule is currently paused on the device. Are you sure you want to continue the schedule?',
  'news.cancelNews.title': 'Broadcast Schedule Cancellation',
  'news.cancelNews.content': 'Are you to cancel the selected broadcast schedules?',
  'news.delete.title': 'Broadcast Schedule Deletion',
  'news.delete.content': 'This broadcast schedule will be deleted from the system',
  'news.filter.type': 'Broadcast Type',
  'news.filter.type.normal': 'Normal Schedule',
  'news.filter.type.prioritize': 'Priority Schedule',
  'news.filter.approvalStatus': 'Status',
  'news.filter.approvalStatus.pending': 'Waiting for Approval',
  'news.filter.approvalStatus.refuse': 'Refused',
  'news.filter.playingStatus.not.broadcasted': 'Unprocessed',
  'news.filter.playingStatus.broadcasting': 'Broadcasting',
  'news.filter.playingStatus.pause': 'Paused',
  'news.filter.playingStatus.cancelled': 'Canceled',
  'news.filter.playingStatus.ended': 'Finished',
  'news.filter.playingStatus.waiting': 'Waiting for Broadcast',
  'news.filter.playingStatus.coming': 'Upcoming',
  'news.filter.repeatType': 'Repetition Type',
  'news.filter.repeatType.none': 'No Repetition',
  'news.filter.repeatType.daily': 'Daily',
  'news.filter.repeatType.weekly': 'Weekly',
  'news.filter.repeatType.monthly': 'Monthly',
  'news.filter.time': 'Start Time',
  'news.refuse.approval.title': 'Broadcast Schedule Rejection',
  'news.refuse.approval.content': 'Are you sure to reject the selected broadcast schedule?',
  'news.refuse.approval.label': 'Reason for Refusal',
  'news.form.refuse.approval.label': 'Reason for Refusal',
  'news.form.refuse.approval.placeholder': 'Please enter the reason for refusal',
  'news.table.title': 'Title',
  'news.table.type': 'Broadcast Type',
  'news.table.type.normal': 'Normal Schedule',
  'news.table.type.prioritize': 'Priority Schedule',
  'news.table.bulletinStatus': 'Status',
  'news.table.bulletinStatus.id':
    '{status, select, 1 {Waiting for Approval} 2 {Refused} 3 {Canceled} 4 {Unprocessed} 5 {Broadcasting} 6 {Paused} 7 {Finished} 8 {Waiting for Broadcast} 9 {Upcoming} other {--}}',
  'news.table.repeatType': 'Repetition Type',
  'news.table.repeatType.none': 'No Repetition',
  'news.table.repeatType.daily': 'Daily',
  'news.table.repeatType.weekly': 'Weekly',
  'news.table.repeatType.monthly': 'Monthly',
  'news.table.finalDate': 'Broadcast Date',
  'news.table.timeSlots': 'Broadcast Time',
  'news.table.facilities': 'Location',
  'news.table.creator.name': 'Creator',
  'news.router.info.detail.name': 'Broadcast Information',
  'news.router.info.history.name': 'Broadcast History',
  'news.info.tabs.detail': 'Broadcast Information',
  'news.info.tabs.history': 'Broadcast History',
  'news.info.detail.lastTime.title': 'Cannot Create Broadcast',
  'news.info.detail.lastTime.content': 'Cannot create follow-up Broadcast because the day is over',
  'news.info.detail.table.name': 'Media',
  'news.info.detail.table.template': 'Template',
  'news.info.detail.table.duration': 'Duration',
  'news.info.detail.table.icon.play': 'Play',
  'news.info.detail.title.label': 'Title',
  'news.info.detail.type': 'Broadcast Type',
  'news.info.detail.type.normal': 'Normal Schedule',
  'news.info.detail.type.prioritize': 'Priority Schedule',
  'news.info.detail.field.name': 'Category',
  'news.info.detail.sourceType': 'Media Type:',
  'news.info.detail.media': 'Media',
  'news.info.detail.template': 'Template',
  'news.info.detail.playlist': 'Playlist',
  'news.info.detail.sourceType.name': 'Media',
  'news.info.detail.approvalStatus': 'Status',
  'news.info.detail.bulletinStatus.id':
    '{status, select, 1 {Waiting for Approval} 2 {Refused} 3 {Canceled} 4 {Unprocessed} 5 {Broadcasting} 6 {Paused} 7 {Finished} 8 {Waiting for Broadcast} 9 {Upcoming} other {--}}',
  'news.info.detail.repeatType.none': 'No Repeat',
  'news.info.detail.repeatType.daily': 'Daily',
  'news.info.detail.repeatType.weekly': 'Weekly',
  'news.info.detail.repeatType.monthly': 'Monthly',
  'news.info.detail.sunday': 'Sunday',
  'news.info.detail.monday': 'Monday',
  'news.info.detail.tuesday': 'Tuesday',
  'news.info.detail.wednesday': 'Wednesday',
  'news.info.detail.thursday': 'Thursday',
  'news.info.detail.friday': 'Friday',
  'news.info.detail.saturday': 'Saturday',
  'news.info.detail.finalDate': 'Broadcast Date',
  'news.info.detail.timeSlots': 'Number of Repetition',
  'news.info.detail.timeSlots.count.1': 'Broadcast',
  'news.info.detail.timeSlots.count.2': 'times',
  'news.info.detail.type.same': 'Broadcast Mode',
  'news.info.detail.repeatType.title': 'Broadcast Repetition',
  'news.info.detail.action.add': 'Create Next Schedule',
  'news.info.detail.action.plusSquare': 'Copy Schedule',
  'news.info.detail.icon.pause': 'Paused',
  'news.info.detail.pause.title': 'Confirm Pause Broadcast',
  'news.info.detail.pause.content':
    'The broadcast schedule is being broadcast on the device. Are you sure you want to pause the broadcast schedule?',
  'news.info.detail.icon.continue': 'Continue Broadcasting',
  'news.info.detail.continue.title': 'Confirm Continue Broadcast',
  'news.info.detail.continue.content':
    'The broadcast schedule is currently paused on the device. Are you sure you want to continue the broadcast schedule?',
  'news.info.detail.approval.title': 'Broadcast Schedule Approval Confirmation',
  'news.info.detail.approval.content': 'The selected broadcast schedule will be approved.',
  'news.info.detail.cancelNews.title': 'Broadcast Schedule Cancellation',
  'news.info.detail.cancelNews.content': 'Are you to cancel the selected broadcast schedules?',
  'news.info.detail.delete.name': 'Delete Broadcast Schedule',
  'news.info.detail.delete.title': 'Broadcast Schedule Deletion',
  'news.info.detail.delete.content': 'This Broadcast Schedule will be deleted from the system',
  'news.info.detail.title.1': '1. Basic Information',
  'news.info.detail.title.2': '2. Location',
  'news.info.detail.title.3': '3. Media List',
  'news.info.detail.title.4': '4. Schedule',
  'news.info.detail.refuse.approval.label': 'Reason for Refusal',
  'news.info.detail.device.name': '2. Devices',
  'news.info.detail.facilities.operationStatus': 'Location Status',
  'news.info.detail.devices.operationStatus': 'Device Status',
  'news.info.detail.title': 'Broadcast Information',
  'news.info.history.title': 'Broadcast History',
  'news.info.history.table.device.code': 'Device Code',
  'news.info.history.table.device.name': 'Device Name',
  'news.info.history.table.startTime': 'Start Time',
  'news.info.history.table.endTime': 'End Time',
  'news.info.history.table.newsletter.facilities': 'Location',
  'news.info.history.filter.startTime': 'Start Time',
  'news.breadcrumbs.list': 'Broadcast List',
  'news.breadcrumbs.edit': 'Edit Broadcast',
  'news.breadcrumbs.add': 'Add Broadcast',
  'news.create.title': 'Add New Broadcast',
  'news.edit.title': 'Edit Broadcast',
  'news.create.facility.schedule.title': '2. Schedule',
  'news.create.facility.sunday': 'Sunday',
  'news.create.facility.monday': 'Monday',
  'news.create.facility.tuesday': 'Tuesday',
  'news.create.facility.wednesday': 'Wednesday',
  'news.create.facility.thursday': 'Thursday',
  'news.create.facility.friday': 'Friday',
  'news.create.facility.saturday': 'Saturday',
  'news.create.facility.schedule.form.type': 'Broadcast Mode',
  'news.create.facility.schedule.form.type.normal': 'Scheduled Broadcast',
  'news.create.facility.schedule.form.type.prioritize': 'Immediate Broadcast',
  'news.create.facility.schedule.form.timeSlot': 'Broadcast Time',
  'news.create.facility.schedule.form.timeSlot.timeRelease': 'By Duration',
  'news.create.facility.schedule.form.timeSlot.timeSlot': 'By Timeframe',
  'news.create.facility.schedule.form.dayFrom': 'Start Date',
  'news.create.facility.schedule.form.validatorReleaseDate':
    'Start date must be earlier than end date.',
  'news.create.facility.schedule.form.validatorReleaseDateRequired':
    'Please enter the broadcast date',
  'news.create.facility.schedule.form.dayTo': 'Broadcast Schedule End Date',
  'news.create.facility.schedule.form.timeSlots.validator.wrongPosition':
    'Broadcast time must be in ascending order',
  'news.create.facility.schedule.form.timeSlots.validator': 'Broadcast times cannot overlap',
  'news.create.facility.schedule.form.timeSlots.validator-1':
    'Start time must be earlier than end time',
  'news.create.facility.schedule.form.timeSlots.validator-required': 'Please select the time.',
  'news.create.facility.schedule.form.timeSlots.endTime.required': 'Please select the time.',
  'news.create.facility.schedule.form.timeSlots.over.required':
    'End time cannot be later than 23:59:59',
  'news.create.facility.schedule.form.numberRepeat': 'Number of Repetition',
  'news.create.facility.schedule.repeatForm.form.typeRepeat': 'Broadcast Repetition',
  'news.create.facility.schedule.repeatForm.repeat.none': 'No Repetition',
  'news.create.facility.schedule.repeatForm.repeat.all': 'Daily Repeat',
  'news.create.facility.schedule.repeatForm.repeat.day': 'Weekly Repeat on',
  'news.create.facility.schedule.repeatForm.repeat.date': 'Monthly Repeat on',
  'news.create.facility.schedule.info.form.name': 'Title',
  'news.create.facility.schedule.info.form.field': 'Category',
  'news.create.facility.schedule.info.form.field.placeholder': 'Please select a category',
  'news.create.facility.schedule.info.form.facility': 'Location',
  'news.create.facility.schedule.info.form.placeholder': 'Please select a location',
  'news.create.facility.schedule.info.form.location': '1. Broadcast Information',
  'news.create.facility.schedule.info.form.sourceType': 'Media Type',
  'news.create.facility.schedule.info.form.sourceType.placeholder': 'Please select a media type',
  'news.create.facility.schedule.info.form.template': 'Template',
  'news.create.facility.schedule.info.form.media': 'Media',
  'news.create.facility.schedule.info.form.playlist': 'Playlist',
  'news.create.facility.schedule.info.form.sourceId': 'Media',
  'news.create.facility.schedule.info.form.sourceId.placeholder': 'Please select a Media',
  'news.create.facility.schedule.info.form.sourceDuration': 'Playback Duration',
  'news.create.facility.schedule.info.form.validatorMinimumDuration':
    'Playback Duration must be greater than 00:00:00',
  'news.create.facility.schedule.info.form.validatorRequiredDuration':
    'Playback Duration cannot be empty',
  'news.create.facility.schedule.modal.conflict.title': 'List of Conflicting Broadcast Schedules',
  'news.create.facility.schedule.modal.conflict.okText': 'Override',
  'news.create.facility.schedule.modal.conflict.facilityPlay': 'Location',
  'news.create.facility.schedule.modal.conflict.facilityLastItem':
    'At least one broadcast location is required.',
  'news.create.facility.schedule.modal.conflict.table.newsletter.title': 'Broadcast schedule',
  'news.create.facility.schedule.modal.conflict.table.newsletter.facility.name':
    'Creating Location',
  'news.create.facility.schedule.modal.conflict.table.facility': 'Broadcast Location',
  'news.create.facility.schedule.modal.conflict.table.newsletter': 'Broadcast Time',
  'news.create.facility.schedule.modal.conflict.table.repeat.none': 'No Repetition',
  'news.create.facility.schedule.modal.conflict.table.repeat.all': 'Daily Repeat',
  'news.create.facility.schedule.modal.conflict.table.repeat.day': 'Weekly Repeat on Date',
  'news.create.facility.schedule.modal.conflict.table.repeat.date': 'Monthly Repeat on Date',
  'news.create.facility.schedule.modal.conflict.table.nearestConflictTime': 'Nearest Conflict Time',
  'news.create.facility.schedule.modal.conflict.table.overlap': 'Overlap',
  'news.create.facility.schedule.modal.conflict.table.rejectNews': 'Refused',
  'news.create.facility.schedule.modal.conflict.table.cancelNews': 'Cancel Broadcast Schedule',
  'news.create.facility.schedule.modal.conflict.table.cancelNews.title':
    'Broadcast Schedule Cancellation',
  'news.create.facility.schedule.modal.conflict.table.cancelNews.content':
    'Are you to cancel the selected broadcast schedule(s)?',
  'news.create.device.sunday': 'Sunday',
  'news.create.device.monday': 'Monday',
  'news.create.device.tuesday': 'Tuesday',
  'news.create.device.wednesday': 'Wednesday',
  'news.create.device.thursday': 'Thursday',
  'news.create.device.friday': 'Friday',
  'news.create.device.saturday': 'Saturday',
  'news.create.device.form.validate': 'Device cannot be empty',
  'news.create.device.source.title': '3. Media',
  'news.create.device.source.form.sourceType': 'Media Type',
  'news.create.device.source.form.sourceType.placeholder': 'Please select media type',
  'news.create.device.source.form.template': 'Template',
  'news.create.device.source.form.media': 'Media',
  'news.create.device.source.form.playlist': 'Playlist',
  'news.create.device.source.form.sourceId': 'Media',
  'news.create.device.source.form.sourceId.placeholder': 'Please select media source',
  'news.create.device.source.form.sourceDuration': 'Duration',
  'news.create.device.source.form.validatorMinimumDuration':
    'Duration must be greater than 00:00:00',
  'news.create.device.source.form.validatorRequiredDuration': 'Duration cannot be empty',
  'news.create.device.schedule.form.type': 'Broadcast Mode',
  'news.create.device.schedule.form.schedule.': 'Scheduled Broadcast',
  'news.create.device.schedule.form.schedule.after-approve': 'Immediate Broadcast ',
  'news.create.device.schedule.form.timeSlot': 'Broadcast Time',
  'news.create.device.schedule.form.timeSlot.timeRelease': 'By Duration',
  'news.create.device.schedule.form.timeSlot.timeSlot': 'By Timeframe',
  'news.create.device.schedule.form.dayFrom': 'Start Date',
  'news.create.device.schedule.form.validatorReleaseDate':
    'Start date must be earlier than end date.',
  'news.create.device.schedule.form.validatorReleaseDateRequired': 'Please enter start date',
  'news.create.device.schedule.form.dayTo': 'End Date',
  'news.create.device.schedule.titleSchedule': '4. Broadcast Schedule',
  'news.create.device.schedule.form.validatorTimeSlots-wrongPosition':
    'Broadcast times must be in ascending order',
  'news.create.device.schedule.form.validatorTimeSlots': 'Repeat times must not overlap',
  'news.create.device.schedule.form.validatorTimeSlots-1':
    'Start time must be earlier than end time',
  'news.create.device.schedule.form.validatorTimeSlots-required': 'Please select time.',
  'news.create.device.schedule.form.validatorTimeSlots-overDay': 'End time must be before 23:59:59',
  'news.create.device.schedule.form.numberRepeat': 'Number of Repetition',
  'news.create.device.schedule.repeatForm.typeRepeat': 'Broadcast Repetition',
  'news.create.device.schedule.repeatForm.typeRepeat.none': 'No Repetition',
  'news.create.device.schedule.repeatForm.typeRepeat.all': 'Daily Repeat',
  'news.create.device.schedule.repeatForm.typeRepeat.day': 'Weekly Repeat on Date',
  'news.create.device.schedule.repeatForm.typeRepeat.date': 'Monthly Repeat on Date',
  'news.create.device.compo.title': '2. Device',
  'news.create.device.compo.placeholderName': 'Enter device name',
  'news.create.device.compo.table.name': 'Device',
  'news.create.device.compo.table.facility.name': 'Location',
  'news.create.device.compo.table.specificAddress': 'Installation Address',
  'news.create.device.compo.modal.search.text': 'Selected Device List',
  'news.create.device.compo.modal.title': 'Select Broadcast Device',
  'news.create.device.compo.modal.facility': 'Location',
  'news.create.device.compo.modal.facility.placeholder': 'Please select Location',
  'news.create.device.compo.modal.tableDevices.table.name': 'Device',
  'news.create.device.compo.modal.tableDevices.table.facility.name': 'Location',
  'news.create.device.compo.modal.tableDevices.table.specificAddress': 'Installation Address',
  'news.create.device.compo.modal.tableDevicesSelected.table.name': 'Device',
  'news.create.device.compo.modal.tableDevicesSelected.table.facility.name': 'Location',
  'news.create.device.compo.modal.tableDevicesSelected.table.specificAddress':
    'Installation Address',
  'news.create.device.compo.modal.tableDevicesSelected.table.remove': 'Remove',
  'news.create.device.modal.conflict.title': 'Conflicting Broadcast Schedule List',
  'news.create.device.modal.conflict.okText': 'Overwrite',
  'news.create.device.modal.conflict.deviceName': 'Device',
  'news.create.device.modal.conflict.deviceLastItem': 'At least one broadcast device is required',
  'news.create.device.modal.conflict.table.newsletter.title': 'Broadcast Schedule',
  'news.create.device.modal.conflict.table.newsletter.facility.name': 'Creating Location',
  'news.create.device.modal.conflict.table.device': 'Device',
  'news.create.device.modal.conflict.table.newsletter': 'Broadcast Time',
  'news.create.device.modal.conflict.table.nearestConflictTime': 'Nearest Conflict Time',
  'news.create.device.modal.conflict.table.repeat.none': 'No Repetition',
  'news.create.device.modal.conflict.table.repeat.all': 'Daily Repeat',
  'news.create.device.modal.conflict.table.repeat.day': 'Weekly Repeat on Date',
  'news.create.device.modal.conflict.table.repeat.date': 'Monthly Repeat on Date',
  'news.create.device.modal.conflict.table.overlap': 'Overlap',
  'news.create.device.modal.conflict.table.rejectNews': 'Refused',
  'news.create.device.modal.conflict.table.cancelNews': 'Cancel Broadcast Schedule',
  'news.create.device.modal.conflict.table.cancelNews.title': 'Confirm Cancel Broadcast Schedule',
  'news.create.device.modal.conflict.table.cancelNews.content':
    'The selected Broadcast Schedule will be canceled',
  'news.create.device.info.location.title': '1. Broadcast Schedule Information',
  'news.create.device.info.form.name': 'Title',
  'news.create.device.info.form.field': 'Category',
  'news.create.device.info.form.field.placeholder': 'Please select category',
  'subNews.router.info.detail.name': 'Broadcast Schedule Information',
  'subNews.router.info.history.name': 'Broadcast Schedule History',
  'subNews.router.name': 'Sub-location broadcast schedule',
  'subNews.title': 'Sub-location broadcast schedule list',
  'subNews.table.title': 'Title',
  'subNews.table.type': 'Broadcast Type',
  'subNews.table.bulletinStatus': 'Status',
  'subNews.table.repeatType': 'Repetition Type',
  'subNews.table.finalDate': 'Broadcast Date',
  'subNews.table.timeSlots': 'Broadcast Time',
  'subNews.table.facilities': 'Broadcast Location',
  'subNews.table.creator.name': 'Creator',
  'subNews.info.tabs.label.1': 'Broadcast Information',
  'subNews.info.tabs.label.2': 'Broadcast History',
  'subNews.info.history.title': 'Broadcast History',
  'subNews.info.history.table.device.code': 'Device Code',
  'subNews.info.history.table.device.name': 'Device Name',
  'subNews.info.history.table.startTime': 'Start Time',
  'subNews.info.history.table.endTime': 'End Time',
  'subNews.info.history.table.newsletter.facilities': 'Location',
  'subNews.info.history.filter.startTime': 'Start Time',
  'news.create.device.compo.modal.tableDevicesSelected.table.add.all': 'Add all',
  'news.create.device.compo.modal.tableDevicesSelected.table.remove.all': 'Remove all',
  'news.create.device.compo.table.deviceGroup': 'Device Group(s)',
  'news.create.device.compo.modal.deviceGroup': 'Device Group(s)',
  'news.create.device.compo.modal.deviceGroup.placeholder': 'Please select device group(s)',
  'news.create.device.compo.modal.device.list': 'Device List',
  'news.info.detail.table.device.name': 'Device',
  'news.info.detail.table.device.facility.name': 'Location',
  'news.info.detail.table.device.deviceGroups': 'Device Group(s)',
};
