export default {
  'facility.router.name': 'Location',
  'facility.router.nameBreadcrumb': 'Location Management',
  'facility.tabs.area': 'Location Information',
  'facility.tabs.user': 'User Management',
  'facility.tabs.device': 'Devices',
  'facility.tabs.media': 'Media',
  'facility.tabs.template': 'Templates',
  'facility.tabs.playlist': 'Playlists',
  'facility.tree.delete.title': 'Location Deletion Confirmation',
  'facility.tree.delete.content':
    'Are you sure to delete location {name}, your action can not be reversed? ',
  'facility.tree.dropdown.add': 'Add Location ',
  'facility.tree.dropdown.edit': 'Update Location Information',
  'facility.tree.dropdown.delete': 'Location Deletion Confirmation',
  'facility.info.title': 'Location Information',
  'facility.info.code': 'Location Code',
  'facility.info.name': 'Location Name',
  'facility.info.parentId': 'Subordinate to',
  'facility.info.operationStatus': 'Status',
  'facility.info.operationStatus.param': '{status,select, 1 {Active} 2 {Inactive} other {Other}}',
  'facility.modal.create': 'Add Location',
  'facility.modal.update': 'Update Location Information',
  'facility.modal.form.code': 'Location Code',
  'facility.modal.form.name': 'Location Name ',
  'facility.modal.form.parentId': 'Subordinate to',
  'facility.modal.form.operationStatus': 'Status',
  'facility.router.user.name': 'User Management',
  'facility.user.title': 'Location Admin Accounts',
  'facility.user.table.username': 'Username',
  'facility.user.table.name': 'Full Name',
  'facility.user.table.phoneNumber': 'Phone Number',
  'facility.user.table.email': 'Email',
  'facility.user.table.role.name': 'Role',
  'facility.user.table.status': 'Status',
  'facility.user.table.status.params': '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'facility.user.table.lastAccessedAt': 'Last Accessed',
  'facility.user.delete.title': 'Confirm Account Deletion',
  'facility.user.delete.content':
    'The selected location admin accounts will be deleted and cannot be recovered.',
  'facility.user.filter.role': 'Role',
  'facility.user.filter.status': 'Status',
  'facility.user.filter.status.active': 'Active',
  'facility.user.filter.status.notActive': 'Inactive',
  'facility.user.modal.information': 'User Information',
  'facility.user.modal.create': 'Create User',
  'facility.user.modal.update': 'Update User',
  'facility.user.modal.form.username': 'Username',
  'facility.user.modal.form.username.validatorUserName': 'Username is not in the correct format',
  'facility.user.modal.form.username.placeholder': 'Please enter the login username',
  'facility.user.modal.form.username.note': 'The username to access is',
  'facility.user.modal.form.name': 'Full Name',
  'facility.user.modal.form.name.validatorUndefined': 'Full Name is not in the correct format',
  'facility.user.modal.form.email': 'Email',
  'facility.user.modal.form.timezoneId': 'Timezone',
  'facility.user.modal.form.timezoneId.validator': 'Please enter the timezone',
  'facility.user.modal.form.password': 'Password',
  'facility.user.modal.form.password.required':
    'Password must be at least 8 characters long, including lowercase letters, uppercase letters, numbers, and special characters.',
  'facility.user.modal.form.phoneNumber': 'Phone Number',
  'facility.user.modal.form.identifierNumber': 'ID Card/Passport Number',
  'facility.user.modal.form.dayofBirth': 'Date of Birth',
  'facility.user.modal.form.roleId': 'Role',
  'facility.user.modal.form.roleId.validator': 'Please enter the role',
  'facility.user.modal.form.status': 'Status',
  'facility.user.modal.form.status.validator': 'Please enter the status',
  'facility.user.modal.form.status.active': 'Active',
  'facility.user.modal.form.status.notActive': 'Inactive',
  'facility.user.modal.form.confirmPassWord': 'Confirm Password',
  'facility.user.modal.form.confirmPassWord.required': 'The passwords you entered do not match',
  'facility.user.modal.form.userAvatar': 'Avatar',
  'facility.router.device.name': 'Location Device Information',
  'facility.device.title': 'Location Device List',
  'facility.device.table.name': 'Device Name',
  'facility.device.table.code': 'Identification Code',
  'facility.device.table.specificAddress': 'Installation Address',
  'facility.device.table.facility.name': 'Location',
  'facility.device.table.operationStatus': 'Operational Status',
  'facility.device.table.connectionStatus': 'Connection Status',
  'facility.device.table.playingStatus': 'Broadcast Status',
  'facility.device.table.operationStatus.param':
    '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'facility.device.table.connectionStatus.param':
    '{status,select, 1 {Connected} 2 {Disconnected} other {Undefined}}',
  'facility.device.table.playingStatus.param':
    '{status,select, 1 {Broadcasting} 2 {Not Broadcasting} 3 {Offline Check} other {Unknown}}',
  'facility.device.delete.title': 'Device Deletion Confirmation',
  'facility.device.delete.content':
    'Are you sure to delete the selected device(s), this action can not be reversed?',
  'facility.device.restart.title': 'Device Restart Confirmation',
  'facility.device.restart.content': 'The selected device(s) will be restarted',
  'facility.device.qrCode.title': 'Device Login Code',
  'facility.device.qrCode.text': 'Scan the QR code or enter the code to log in to the device',
  'facility.device.volume.title': 'Device Volume Control ',
  'facility.device.filter.operationStatus': 'Operational Status',
  'facility.device.filter.operationStatus.active': 'Active',
  'facility.device.filter.operationStatus.notActive': 'Inactive',
  'facility.device.filter.connectionStatus': 'Connection Status',
  'facility.device.filter.connectionStatus.active': 'Connected',
  'facility.device.filter.connectionStatus.notActive': 'Disconnected',
  'facility.device.filter.playingStatus': 'Broadcast Status',
  'facility.device.filter.playingStatus.active': 'Broadcasting',
  'facility.device.filter.playingStatus.notActive': 'Not Broadcasting',
  'facility.device.filter.playingStatus.undefined': 'Offline Check',
  'facility.device.modal.create': 'Add Device',
  'facility.device.modal.update': 'Update Device',
  'facility.device.modal.form.name': 'Device Name',
  'facility.device.modal.form.name.validator': 'Device name is not in the correct format',
  'facility.device.modal.form.code': 'Device Identification Code',
  'facility.device.modal.form.code.validator':
    'Device identification code is not in the correct format',
  'facility.device.modal.form.subscriberNumber': 'SIM Number',
  'facility.device.modal.form.serialNumber': 'SIM Serial Number',
  'facility.device.modal.form.supplierId': 'Provider',
  'facility.device.info.detail.sidebar.sdFree': 'Device Capacity',
  'facility.device.info.detail.sidebar.version': 'Version',
  'facility.device.modal.form.operationStatus': 'Operational Status',
  'facility.device.modal.form.operationStatus.active': 'Active',
  'facility.device.modal.form.operationStatus.notActive': 'Inactive',
  'facility.device.modal.form.description': 'Description',
  'facility.router.device.info.name': 'Device Information',
  'facility.device.info.tabs.detail': 'Device Information',
  'facility.device.info.tabs.broadcast': 'Broadcast History',
  'facility.device.info.tabs.activity': 'Device Activity History',
  'facility.device.info.detail.sidebar.title': 'Device Information',
  'facility.device.info.detail.sidebar.name': 'Device Name',
  'facility.device.info.detail.sidebar.code': 'Device Identification Code',
  'facility.device.info.detail.sidebar.specificAddress': 'Address',
  'facility.device.info.detail.sidebar.subscriberNumber': 'SIM Number',
  'facility.device.info.detail.sidebar.serialNumber': 'SIM Serial Number',
  'facility.device.info.detail.sidebar.supplierId': 'Provider',
  'facility.device.info.detail.sidebar.operationStatus': 'Operational Status',
  'facility.device.info.detail.sidebar.operationStatus.param':
    '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'facility.device.info.detail.sidebar.connectionStatus': 'Connection Status',
  'facility.device.info.detail.sidebar.connectionStatus.param':
    '{status,select, 1 {Connected} 2 {Disconnected} other {Undefined}}',
  'facility.device.info.detail.sidebar.playingStatus': 'Broadcast Status',
  'facility.device.info.detail.sidebar.playingStatus.param':
    '{status,select, 1 {Broadcasting} 2 {Not Broadcasting} 3 {Offline Check} other {Unknown}}',
  'facility.device.info.detail.sidebar.description': 'Description',
  'facility.device.info.detail.sidebar.location': 'Location',
  'facility.device.info.detail.delete.title': 'Device Deletion Confirmation',
  'facility.device.info.detail.delete.content':
    'Are you sure to delete the selected device(s), this action can not be reversed?',
  'facility.device.info.detail.restart.title': 'Device Restart Confirmation',
  'facility.device.info.detail.restart.content': 'The selected device(s) will be restarted',
  'facility.device.info.playLogs.title': 'Broadcast History',
  'facility.device.info.playLogs.table.newsletter.title': 'Broadcast Name',
  'facility.device.info.playLogs.table.duration': 'Duration',
  'facility.device.info.playLogs.table.startTime': 'Start Time',
  'facility.device.info.playLogs.table.endTime': 'End Time',
  'facility.device.info.playLogs.filter.createdAt': 'Start Time',
  'facility.device.info.activityHistory.title': 'Device Activity History',
  'facility.device.info.activityHistory.table.time': 'Action Time',
  'facility.device.info.activityHistory.table.actionType': 'Action',
  'facility.device.info.activityHistory.table.note': 'Content',
  'facility.device.info.activityHistory.filter.createdAt': 'Start Time',
  'facility.device.info.activityHistory.table.actionType.downloadSuccessfully':
    'Downloaded Successfully',
  'facility.device.info.activityHistory.table.actionType.downloadError': 'Download Failed',
  'facility.device.info.activityHistory.table.actionType.broadcast': 'Broadcast',
  'facility.device.info.activityHistory.table.actionType.paused': 'Paused Broadcast',
  'facility.device.info.activityHistory.table.actionType.requestServer': 'Deleted Broadcast',
  'facility.device.info.activityHistory.table.actionType.expired': 'Deleted Expired Broadcast',
  'facility.device.info.activityHistory.table.actionType.connected': 'Reconnected',
  'facility.device.info.activityHistory.table.actionType.disconnected': 'Disconnected',
  'facility.device.info.activityHistory.table.actionType.reboot': 'Restarted Device',
  'facility.device.info.activityHistory.table.actionType.adjustVolume': 'Adjusted Volume',
  'facility.device.info.activityHistory.table.actionType.news.letter.ended': 'Broadcast Ended',
  'facility.device.info.activityHistory.table.actionType.news.letter.resume': 'Resumed Broadcast',
  'facility.device.info.activityHistory.table.actionType.changeInfoSource':
    'Changed Information Source',
  'facility.media.title': 'Media List',
  'facility.media.card.creator': 'Creator',
  'facility.media.card.created': 'Created Time',
  'facility.media.card.type': 'Media Type',
  'facility.media.card.processingStatus.param':
    '{status,select, 1 {Processing} 2 {Processed} other {Other}}',
  'facility.media.card.status.param':
    '{status,select, 1 {Approved} 2 {Wait for Approval} 3 {Rejected} other {Other}}',
  'facility.media.code.format.video': 'Video',
  'facility.media.code.format.image': 'Image',
  'facility.media.code.format.stream': 'Streaming',
  'facility.media.code.format.fmhz': 'FM Channel',
  'facility.media.code.format.audio': 'Audio',
  'facility.media.delete.title': 'Media Deletion Confirmation',
  'facility.media.delete.content':
    'The selected media files will be deleted from the system and cannot be restored.',
  'facility.media.approve.title': 'Media Approval Confirmation',
  'facility.media.approve.content': 'Will selected Media be approved for broadcasting?',
  'facility.media.filter.approvalStatus': 'Status',
  'facility.media.filter.approvalStatus.approve': 'Approved',
  'facility.media.filter.approvalStatus.notApprovedYet': 'Waiting for Approval',
  'facility.media.filter.approvalStatus.refuse': 'Refused',
  'facility.media.filter.format.code': 'Media Type',
  'facility.media.filter.field.id': 'Category',
  'facility.media.filter.createdAt': 'Created Date',
  'facility.media.modal.create': 'Add Media',
  'facility.media.modal.update': 'Update Media File',
  'facility.media.modal.notEnough.capacity.title': 'You have run out of storage space',
  'facility.media.modal.notEnough.capacity.content':
    'Please change your subscription plan or free up storage space to upload new media.',
  'facility.media.modal.form.name': 'Media Name',
  'facility.media.modal.form.name.invalid': 'Invalid media file name',
  'facility.media.modal.form.fieldId': 'Category',
  'facility.media.modal.form.resourceType': 'Content',
  'facility.media.modal.form.resourceType.0': 'File',
  'facility.media.modal.form.resourceType.1': 'Download from URL',
  'facility.media.modal.form.resourceType.2': 'Live Streaming',
  'facility.media.modal.form.resourceType.3': 'FM Channel',
  'facility.media.modal.form.file': 'File',
  'facility.media.modal.form.resourceUrl.url': 'URL',
  'facility.media.modal.form.resourceUrl.stream': 'Live stream link',
  'facility.media.modal.form.resourceUrl.fm': 'Channel frequency',
  'facility.media.modal.refuse.approval.title': 'Refuse to Approve Media',
  'facility.media.modal.refuse.approval.content':
    'Are you sure to refuse to approve these media files?',
  'facility.media.modal.refuse.approval.reasonRefusal.label': 'Reason for Refusal',
  'facility.media.modal.refuse.approval.reasonRefusal.placeholder': 'Please enter reasons',
  'facility.media.modal.shareMedia.title': 'Sharing Media',
  'facility.media.modal.shareMedia.tree.title': 'Choose location to share',
  'facility.media.modal.shareMedia.tree.required':
    'Please select at least one location to share with',
  'facility.media.modal.shareMedia.info.title': 'Media Information',
  'facility.media.modal.shareMedia.info.name': 'Media Name',
  'facility.media.modal.shareMedia.info.field': 'Category',
  'facility.media.modal.shareMedia.info.file.type': 'Media Type',
  'facility.media.modal.shareMedia.info.time': 'Broadcast Duration',
  'facility.media.modal.shareMedia.code.format.video': 'Video',
  'facility.media.modal.shareMedia.code.format.image': 'Image',
  'facility.media.modal.shareMedia.code.format.stream': 'Streaming',
  'facility.media.modal.shareMedia.code.format.fmhz': 'FM Channel',
  'facility.media.modal.shareMedia.code.format.audio': 'Audio',
  'facility.media.info.tabs.media': 'Media Information',
  'facility.media.info.tabs.usageNews': 'Usage Broadcast',
  'facility.media.info.breadcrumbs.usageNews': 'Usage Broadcast',
  'facility.media.info.breadcrumbs.media.name': 'Media File Information',
  'facility.media.info.sidebar.title': 'Media Information',
  'facility.media.info.sidebar.name': 'Media Name',
  'facility.media.info.sidebar.field': 'Category',
  'facility.media.info.sidebar.code.format': 'Media Type',
  'facility.media.info.sidebar.resourceUrl.1': 'Content',
  'facility.media.info.sidebar.resourceUrl.2': 'Content',
  'facility.media.info.sidebar.duration': 'Broadcast Duration',
  'facility.media.info.sidebar.size': 'Capacity',
  'facility.media.info.sidebar.approvalStatus': 'Status',
  'facility.media.info.sidebar.approverName': 'Approver',
  'facility.media.info.sidebar.creatorName': 'Creator',
  'facility.media.info.sidebar.createdAt': 'Created Date',
  'facility.media.info.sidebar.description': 'Description',
  'facility.media.info.sidebar.reasonRefusal': 'Reason for Refusal',
  'facility.media.info.sidebar.facilityShare': 'Shared Location',
  'facility.media.info.sidebar.code.format.video': 'Video',
  'facility.media.info.sidebar.code.format.image': 'Image',
  'facility.media.info.sidebar.code.format.stream': 'Live Streaming',
  'facility.media.info.sidebar.code.format.fmhz': 'FM Channel',
  'facility.media.info.sidebar.code.format.audio': 'Audio',
  'facility.media.info.sidebar.badge.processingStatus.param':
    '{status,select, 1 {Processing} 2 {Processed} other {Other}}',
  'facility.media.info.sidebar.badge.status.param':
    '{status,select, 1 {Approved} 2 {Wait for Approval} 3 {Rejected} other {Other}}',
  'facility.media.info.title': 'Media Information',
  'facility.media.info.table.facility.name': 'Shared Location',
  'facility.media.info.table.sharingTime': 'Sharing Time',
  'facility.media.info.table.receptionStatus': 'Reception Status',
  'facility.media.info.table.receptionStatus.param':
    '{status,select, 1 {Not received} 2 {Received} other {Other}}',
  'facility.media.info.delete.title': 'Confirm Media File Deletion',
  'facility.media.info.delete.content':
    'The selected media files will be deleted from the system and cannot be restored.',
  'facility.media.info.approve.title': 'Media Approval Confirmation',
  'facility.media.info.approve.content': 'Will selected Media be approved for broadcasting?',
  'facility.media.info.filter.sharingTime': 'Sharing Time',
  'facility.media.info.filter.receptionStatus': 'Status',
  'facility.media.info.filter.receptionStatus.notUsedYet': 'Not received',
  'facility.media.info.filter.receptionStatus.used': 'Received',
  'facility.template.router.name': 'Location',
  'facility.template.title': 'Location Template List',
  'facility.template.table.name': 'Template Name',
  'facility.template.table.resolutionWidth': 'Aspect Ratio',
  'facility.template.table.createdAt': 'Created Date',
  'facility.template.table.creator.name': 'Creator',
  'facility.template.table.usageStatus': 'Status',
  'facility.template.table.userStatus.param':
    '{status,select, 1 {Unused} 2 {In Used} other {Unknown}}',
  'facility.template.delete.title': 'Template Deletion Confirmation',
  'facility.template.delete.content':
    'Are you sure to delete selected templates and cannot recover them?',
  'facility.template.filter.createdAt': 'Created Date',
  'facility.template.create.code.format.video': 'Video',
  'facility.template.create.code.format.image': 'Image',
  'facility.template.create.code.format.stream': 'Live Streaming',
  'facility.template.create.code.format.fmhz': 'FM channel',
  'facility.template.create.code.format.audio': 'Audio',
  'facility.template.router.create.name': 'Create template',
  'facility.template.router.create.edit': 'Update template',
  'facility.template.create.audio': 'Used audio',
  'facility.template.create.form.templateName': 'Template name',
  'facility.template.create.form.templateName.placeholder': 'Template name',
  'facility.template.create.form.ratio': 'Aspect Ratio',
  'facility.template.create.form.ratioWidth.required': 'Please enter the width',
  'facility.template.create.form.ratioWidth': 'Width',
  'facility.template.create.form.ratioWidth.placeholder': 'Width',
  'facility.template.create.form.ratioHeight': 'Height',
  'facility.template.create.form.ratioHeight.placeholder': 'Height',
  'facility.template.create.form.ratioHeight.required': 'Please enter the height',
  'facility.template.create.form.duration': 'Duration',
  'facility.template.create.form.duration.placeholder': 'Duration',
  'facility.template.create.form.duration.required': 'Please enter the duration',
  'facility.template.create.form.media': 'Filter',
  'facility.template.create.form.media.placeholder': 'Search ...',
  'facility.template.create.form.size': 'Size (%)',
  'facility.template.create.form.width': 'Width',
  'facility.template.create.form.height': 'Height',
  'facility.template.create.form.position': 'Position (%)',
  'facility.template.create.form.positionX': 'X-axis',
  'facility.template.create.form.positionY': 'Y-axis',
  'facility.template.create.form.zIndex': 'Z-index',
  'facility.template.info.name.breadcrumbs': 'Template Information',
  'facility.template.newsLetters.name.breadcrumbs': 'Usage Broadcast',
  'facility.template.info.tabs': 'Template Information',
  'facility.template.newsLetters.tabs': 'Usage Broadcast',
  'facility.router.playlist.create.name': 'Add location playlist',
  'facility.router.playlist.update.name': 'Update location playlist',
  'facility.router.playlist.info.name': 'Location playlist information',
  'facility.router.playlist.name': 'Location playlist',
  'facility.router.playlist.organization.create.name': 'Create Playlist',
  'facility.router.playlist.organization.update.name': 'Update Playlist',
  'facility.playlist.title': 'Location Playlist',
  'facility.playlist.table.name': 'Playlist Name',
  'facility.playlist.table.duration': 'Broadcast Duration',
  'facility.playlist.table.field.name': 'Category',
  'facility.playlist.table.creator.name': 'Creator',
  'facility.playlist.table.createdAt': 'Created Date',
  'facility.playlist.table.usageStatus': 'Status',
  'facility.playlist.table.usageStatus.param':
    '{status,select, 1 {Unused} 2 {In Used} other {Undefined}}',
  'facility.playlist.delete.title': 'Playlist Deletion Confirmation',
  'facility.playlist.delete.content':
    'Are you sure to delete these selected playlists and cannot recover them?',
  'facility.playlist.filter.fields': 'Category',
  'facility.playlist.filter.createdAt': 'Created Date',
  'facility.playlist.update.title': 'Update Playlist',
  'facility.playlist.create.title': 'Create Playlist',
  'facility.playlist.create.mainCard.title': 'List of Media Source',
  'facility.playlist.create.table.order': 'Playback Order',
  'facility.playlist.create.table.name': 'Media Name',
  'facility.playlist.create.table.duration': 'Duration',
  'facility.playlist.create.table.sourceType': 'Selected Media',
  'facility.playlist.create.table.sourceType.1': 'Template',
  'facility.playlist.create.table.sourceType.2': 'Media',
  'facility.playlist.create.code.format.video': 'Video',
  'facility.playlist.create.code.format.image': 'Image',
  'facility.playlist.create.code.format.stream': 'Live Streaming',
  'facility.playlist.create.code.format.fmhz': 'FM Channel',
  'facility.playlist.create.code.format.audio': 'Audio',
  'facility.playlist.create.form.emptySource.warning': 'The list of selected media is empty',
  'facility.playlist.create.form.name.warning': 'Please enter the playlist name',
  'facility.playlist.create.form.name.warning255': 'The length exceeds 255 characters',
  'facility.playlist.create.form.name': 'Name',
  'facility.playlist.create.form.name.placeholder': 'Name',
  'facility.playlist.create.form.field': 'Category',
  'facility.playlist.create.form.field.placeholder': 'Category',
  'facility.playlist.create.form.playMode': 'Play Mode',
  'facility.playlist.create.form.playMode-1': 'In Order',
  'facility.playlist.create.form.playMode-2': 'Random Order',
  'facility.playlist.create.form.duration': 'Total Broadcast Duration',
  'facility.playlist.create.form.duration.placeholder': 'Total Broadcast Duration',
  'facility.playlist.create.form.description': 'Description',
  'facility.playlist.create.form.description.placeholder': 'Description',
  'facility.playlist.create.modal.sourceSelected.title': 'Selected Media',
  'facility.playlist.create.modal.template.table.name': 'Media Name',
  'facility.playlist.create.modal.template.table.duration': 'Duration',
  'facility.playlist.create.modal.template.table.source-count': 'Number of additions',
  'facility.playlist.create.modal.templateDisplay.table.name': 'Name',
  'facility.playlist.create.modal.templateDisplay.table.duration': 'Duration',
  'facility.playlist.create.modal.templateDisplay.table.button.remove': 'Remove',
  'facility.playlist.create.modal.form.duration.warning':
    'The duration of all selected media must be greater than 0',
  'facility.playlist.create.modal.filter.field': 'Category',
  'facility.playlist.create.modal.title': 'Add Media Source',
  'facility.playlist.create.modal.form.sourceType': 'Selected Media',
  'facility.playlist.create.modal.form.sourceType.1': 'Template',
  'facility.playlist.create.modal.form.sourceType.2': 'Media',
  'facility.playlist.create.modal.source.options.title':
    'Select the media source to add to the playlist',
  'facility.playlist.info.tabs.info': 'Playlist Information',
  'facility.playlist.info.tabs.news': 'Usage Broadcast',
  'facility.playlist.info.breadcrumbs.news': 'Usage Broadcast',
  'facility.playlist.info.breadcrumbs.info': 'Playlist Information',
  'facility.playlist.info.title': 'List of Media Source',
  'facility.playlist.info.table.order': 'Playback Order',
  'facility.playlist.info.table.sourceName': 'Media Name',
  'facility.playlist.info.table.duration': 'Duration',
  'facility.playlist.info.table.sourceType': 'Selected Media',
  'facility.playlist.info.table.sourceType.1': 'Template',
  'facility.playlist.info.table.sourceType.2': 'Media',
  'facility.playlist.info.delete.title': 'Playlist Deletion Confirmation',
  'facility.playlist.info.delete.content':
    'Are you sure to delete these selected playlists and cannot recover them?',
  'facility.playlist.info.sidebar.title': 'Playlist Information',
  'facility.playlist.info.sidebar.name': 'Playlist Name',
  'facility.playlist.info.sidebar.field': 'Category',
  'facility.playlist.info.sidebar.creator': 'Creator',
  'facility.playlist.info.sidebar.createdAt': 'Created Time',
  'facility.playlist.info.sidebar.duration': 'Playback Duration',
  'facility.playlist.info.sidebar.playMode': 'Play Mode',
  'facility.playlist.info.sidebar.description': 'Description',
  'facility.playlist.info.sidebar.playMode.1': 'In Order',
  'facility.playlist.info.sidebar.playMode.2': 'Random Order',
  'facility.device.quantity.label': 'Number of devices',
  'facility.device.quantity.warning.title': 'You have reached the device limit',
  'facility.device.quantity.warning.content':
    'The number of devices has reached the limit of the current subscription you are using. You can change to another subscription or remove inactive devices.',
  'facility.device.quantity.warning.btn.upgrade': 'Upgrade',
  'facility.device.quantity.warning.message':
    'You do not have permission to access this feature, please contact the organization owner.',
  'facility.device.inform.title': 'Notification',
  'facility.device.inform.content':
    'You are approaching the device limit of the upcoming plan. According to the plan, by {date}, the new plan will be limited to {deviceQuantity} device(s). You may consider adjusting the plan for a better fit.',
  'facility.device.inform.okText': 'Subscription Management',
  'facility.device.table.deviceGroup': 'Device Group(s)',
  'facility.modal.create.form.deviceGroup': 'Device Group(s)',
  'facility.device.info.detail.sidebar.deviceGroup': 'Device Group(s)',
  'facility.device.filter.deviceGroups': 'Device Group(s)',
  //
  'facility.device.info.activityHistory.filter.actionType': 'Activity',
  'facility.device.info.activityHistory.filter.actionType.downloadSuccessfully':
    'Downloaded Successfully',
  'facility.device.info.activityHistory.filter.actionType.downloadError': 'Download Failed',
  'facility.device.info.activityHistory.filter.actionType.broadcast': 'Broadcast',
  'facility.device.info.activityHistory.filter.actionType.paused': 'Paused Broadcast',
  'facility.device.info.activityHistory.filter.actionType.requestServer': 'Deleted Broadcast',
  'facility.device.info.activityHistory.filter.actionType.expired': 'Deleted Expired Broadcast',
  'facility.device.info.activityHistory.filter.actionType.connected': 'Reconnected',
  'facility.device.info.activityHistory.filter.actionType.disconnected': 'Disconnected',
  'facility.device.info.activityHistory.filter.actionType.reboot': 'Rebooted Device',
  'facility.device.info.activityHistory.filter.actionType.adjustVolume': 'Adjusted Volume',
  'facility.device.info.activityHistory.filter.actionType.newsLetterEnded': 'Broadcast Ended',
  'facility.device.info.activityHistory.filter.actionType.newsLetterResume': 'Resumed Broadcast',
  'facility.device.info.activityHistory.filter.actionType.changeInfoSource':
    'Changed Information Source',
};
