export default {
  'server.notFound': 'Không tồn tại',
  'server.networkError': 'Lỗi mạng',
  'Server.AccountEmail.NotExists': 'Thông tin đăng nhập không đúng',
  'Server.AddBeat.Success': 'Thêm thành công',
  'Server.DeleteBeat.Success': 'Xóa thành công',
  'Server.UpdateBeat.Success': 'Cập nhập thành công',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Sai tên đăng nhập hoặc mật khẩu',
  'Mes.Users.Login.Successfully': 'Đăng nhập thành công',
  'Mes.Classes.Year.MustHave2Characters': 'Năm học phải có 2 kí tự',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Mã lớp học phải có 2 kí tự',
  'Mes.Classes.Add.Successfully': 'Thêm lớp học thành công',
  'Mes.Questions.Update.Successfully': 'Cập nhật câu hỏi thành công',
  'Mes.Questions.Add.Successfully': 'Thêm câu hỏi thành công',
  'Mes.Users.UpdateSuccessfully': 'Cập nhật thông tin tài khoản thành công',
  'Mes.Users.Add.Successfully': 'Thêm tài khoản thành công',
  'Mes.Users.DeleteUsers.Successfully': 'Xóa thành công',
  'Mes.Researchs.Update.Successfully': 'Cập nhật bài nghiên cứu thành công',
  'Mes.Researchs.Add.Successfully': 'Thêm bài nghiên cứu thành công',
  'Mes.Researchs.Delete.Successfully': 'Xóa bài nghiên cứu thành công',
  'server.data.success': 'Thành công',
  Successful: 'Thành công',
  'Mes.Facility.AlreadyExist.Media': 'Media đã được chia sẻ trong cơ sở',
  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'Link khôi phục đã được gửi tới email của bạn',
  'Mes.User.ResetPasswordSuccessfully': 'Đặt lại mật khẩu thành công',
  'Mes.Profile.GetDetailSuccessfully': 'Lấy thông tin tài khoản thành công',
  'Mes.Profile.OldPassword.Wrong': 'Mật khẩu cũ không đúng',
  'Mes.Profile.ChangePasswordSuccessfully': 'Cập nhật mật khẩu thành công',
  'Mes.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Role.CreateSuccessfully': 'Thêm vai trò thành công',
  'Mes.Role.DeleteSuccessfully': 'Xóa vai trò thành công',
  'Mes.Newsletter.Delete.Successfully': 'Xóa bản tin thành công',
  'Mes.Newsletter.Cancel.Successfully': 'Hủy bản tin thành công',
  'Mes.Newsletter.Create.Successfully': 'Thêm bản tin thành công',
  'Mes.Newsletter.Invalid.ApprovalStatus': 'Trạng thái bản tin không hợp lệ',
  'Mes.Template.Create.Successfully': 'Thêm bản mẫu thành công',
  'Mes.Media.Create.Successfully': 'Thêm media thành công',
  'Mes.MediaShare.Delete.Successfully': 'Xóa media được chia sẻ thành công',
  'Mes.Playlist.Create.Successfully': 'Thêm danh sách phát thành công',
  'Mes.BroadcastFacility.AlreadyExist.Newsletter': 'Cơ sở phát đã tồn tại bản tin',
  'Mes.Role.Create.Successfully': 'Tạo vai trò thành công',
  'Mes.Role.Create.Failed': 'Tạo vai trò thất bại',
  'Mes.Role.Update.Successfully': 'Cập nhật vai trò thành công',
  'Mes.Role.Update.Failed': 'Cập nhật vai trò thất bại',
  'Mes.Role.Search.Successfully': 'Xem danh sách vai trò thành công',
  'Mes.Role.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Role.Delete.Successfully': 'Xóa vai trò thành công',
  'Mes.Role.Delete.Failed': 'Xóa vai trò thất bại',
  'Mes.Role.Required.PermissionCodes': 'Mã quyền là bắt buộc',
  'Mes.Role.OverLength.Code': 'Mã vai trò không vượt quá 255 kí tự',
  'Mes.Role.OverLength.Description': 'Mô tả vai trò không vượt quá 255 kí tự',
  'Mes.Role.AlreadyExist.Code': 'Mã vai trò đã tồn tại',
  'Mes.Role.Required.Code': 'Mã vai trò là bắt buộc',
  'Mes.Role.NotWhiteSpace.Code': 'Mã vai trò không có khoảng trắng',
  'Mes.Role.NotSpecialCharacter.Code': 'Mã vai trò không chứa ký tự đặc biệt (trừ dấu . @)',
  'Mes.Role.Required.Name': 'Tên vai trò là bắt buộc',
  'Mes.Role.OverLength.Name': 'Tên vai trò không vượt quá 255 kí tự',
  'Mes.Role.NotFound': 'Vai trò không tồn tại',
  'Mes.Role.Repeated.Ids': 'Id vai trò không lặp lại',
  'Mes.Role.Required.Ids': 'Id vai trò bắt buộc',
  'Mes.Role.WasUsed': 'Vai trò đã được sử dụng',
  'Mes.User.Create.Successfully': 'Tạo người dùng thành công',
  'Mes.User.Create.Failed': 'Tạo người dùng thất bại',
  'Mes.User.Update.Successfully': 'Cập nhật người dùng thành công',
  'Mes.User.Update.Failed': 'Cập nhật người dùng thất bại',
  'Mes.User.Search.Successfully': 'Xem danh sách người dùng thành công',
  'Mes.User.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.User.Delete.Successfully': 'Xóa người dùng thành công',
  'Mes.User.Delete.Failed': 'Xóa người dùng thất bại',
  'Mes.User.Login.Successfully': 'Đăng nhập thành công',
  'Mes.User.Send.Failed': 'Gửi cho người dùng mail thất bại',
  'Mes.User.CheckRecoveryCode.Successfully': 'Kiểm tra mã khôi phục thành công',
  'Mes.User.SendRecoveryCode.Successfully': 'Gửi mã khôi phục thành công',
  'Mes.User.ResetPassword.Successfully': 'Lấy lại mật khẩu thành công',
  'Mes.User.Refresh.Successfully': 'Làm mới thành công',
  'Mes.User.ChangePassword.Successfully': 'Đổi mật khẩu thành công',
  'Mes.User.Profile.Successfully': 'Lấy thông tin cá nhân thành công',
  'Mes.User.Required.Code': 'Mã đăng nhập là bắt buộc',
  'Mes.User.Invalid.Username': 'Tên đăng nhập không hợp lệ',
  'Mes.User.AlreadyExist.Username': 'Tên đăng nhập đã tồn tại',
  'Mes.User.Required.Username': 'Tên đăng nhập là bắt buộc',
  'Mes.User.NotWhiteSpace.Username': 'Tên đăng nhập không có khoảng trắng',
  'Mes.User.NotSpecialCharacter.Username': 'Tên đăng nhập không chứa ký tự đặc biệt (trừ dấu . @)',
  'Mes.User.Invalid.Email': 'Email không hợp lệ',
  'Mes.User.AlreadyExist.Email': 'Email đã tồn tại',
  'Mes.User.Required.Email': 'Email là bắt buộc',
  'Mes.User.NotWhiteSpace.Email': 'Email không có khoảng trắng',
  'Mes.User.OverLength.Name': 'Tên người dùng không vượt quá 250 kí tự',
  'Mes.User.Invalid.AvatarPicture': 'Ảnh đại diện không hợp lệ',
  'Mes.User.Invalid.Password':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt.',
  'Mes.User.Required.Password': 'Mật khẩu là bắt buộc',
  'Mes.User.IsWrong.OldPassword': 'Mật khẩu cũ không đúng',
  'Mes.User.Invalid.OldPassword': 'Mật khẩu cũ không hợp lệ',
  'Mes.User.Invalid.PhoneNumber': 'Số điện thoại không hợp lệ',
  'Mes.User.Required.RoleId': 'Id vai trò là bắt buộc',
  'Mes.User.Status.Required': 'Trạng thái là bắt buộc',
  'Mes.User.Status.Invalid': 'Trạng thái không hợp lệ',
  'Mes.User.Required.RecoveryCode': 'Mã khôi phục là bắt buộc',
  'Mes.User.NotFound.RecoveryCode': 'Mã khôi phục người dùng không tồn tại',
  'Mes.User.NotFound': 'Không tìm thấy người dùng',
  'Mes.User.PleaseCheckEmail': 'Hãy kiểm tra lại email của bạn',
  'Mes.User.Blocked': 'Người dùng đã bị khóa',
  'Mes.UserRefreshToken.Required.Token': 'Mã làm mới là bắt buộc',
  'Mes.UserRefreshToken.WasUsed': 'Mã làm mới đã được sử dụng',
  'Mes.UserRefreshToken.Invalid.Token': 'Mã làm mới không hợp lệ',
  'Mes.UserRefreshToken.Expired.Token': 'Mã làm mới đã hết hạn',
  'Mes.BroadcastDevice.AlreadyExist.Newsletter': 'Cơ sở phát đã tồn tại bản tin',
  'Mes.TimeSlot.Required.StartTime': 'Giờ bắt đầu phát là bắt buộc',
  'Mes.TimeSlot.Required.EndTime': 'Giờ kết thúc phát là bắt buộc',
  'Mes.TimeSlot.Invalid.EndTime': 'Giờ kết thúc phát không hợp lệ',
  'Mes.TimeSlot.Required.LoopCount': 'Số lần lặp là bắt buộc',
  'Mes.TimeSlot.Invalid.LoopCount': 'Số lần lặp không hợp lệ',
  'Mes.Schedule.Required.TimeSlots': 'Khung giờ phát bản tin là bắt buộc',
  'Mes.Schedule.Repeated.TimeSlots': 'Khung giờ phát bản tin lặp lại',
  'Mes.Schedule.Required.FinalDate': 'Ngày kết thúc là bắt buộc',
  'Mes.Schedule.Required.ReleaseDate': 'Ngày bắt đầu là bắt buộc',
  'Mes.Schedule.Invalid.ReleaseDate': 'Ngày bắt đầu không hợp lệ',
  'Mes.Schedule.Invalid.FinalDate': 'Ngày kết thúc không hợp lệ',
  'Mes.Schedule.Invalid.RepeatType': 'Kiểu lặp bản tin không hợp lệ',
  'Mes.Schedule.Required.RepeatType': 'Kiểu lặp bản tin là bắt buộc',
  'Mes.Schedule.MustBeEmpty.RepeatValue': 'Giá trị lặp phải trống',
  'Mes.Schedule.Invalid.RepeatValue': 'Giá trị lặp không hợp lệ',
  'Mes.Schedule.Required.RepeatValue': 'Giá trị lặp là bắt buộc',
  'Mes.Schedule.MustBeEmpty.FinalDate': 'Ngày kết thúc phải trống',
  'Mes.Schedule.MustBeEmpty.TimeSlots': 'Khung giờ phát bản tin phải trống',
  'Mes.Schedule.MustBeEmpty.RepeatType': 'Kiểu lặp bản tin phải trống',
  'Mes.Newsletter.Expired.Duration': 'Thời hạn đã hết hạn',
  'Mes.Newsletter.Expired.Schedule': 'Lịch trình đã hết hạn',
  'Mes.Newsletter.Expired.Playlist': 'Danh sách phát đã hết hạn',
  'Mes.Newsletter.Expired.PlaylistId': 'Mã định danh danh sách phát đã hết hạn',
  'Mes.Newsletter.Expired.Template': 'Mẫu đã hết hạn',
  'Mes.Newsletter.Expired.TemplateId': 'Mã định danh mẫu đã hết hạn',
  'Mes.Newsletter.Expired.Media': 'Phương tiện đã hết hạn',
  'Mes.Newsletter.Expired.MediaId': 'Mã định danh phương tiện đã hết hạn',
  'Mes.Newsletter.Expired.PlayingStatus': 'Trạng thái chơi đã hết hạn',
  'Mes.Newsletter.Expired.ApprovalStatus': 'Trạng thái phê duyệt đã hết hạn',
  'Mes.Newsletter.Expired.SourceType': 'Loại nguồn đã hết hạn',
  'Mes.Newsletter.Expired.Type': 'Loại đã hết hạn',
  'Mes.Newsletter.Expired.Field': 'Lĩnh vực đã hết hạn',
  'Mes.Newsletter.Expired.FieldId': 'Mã định danh lĩnh vực đã hết hạn',
  'Mes.Newsletter.Expired.Facility': 'Cơ sở đã hết hạn',
  'Mes.Newsletter.Expired.FacilityId': 'Mã định danh cơ sở đã hết hạn',
  'Mes.Newsletter.Expired.Organization': 'Tổ chức đã hết hạn',
  'Mes.Newsletter.Expired.OrganizationId': 'Mã định danh tổ chức đã hết hạn',
  'Mes.Newsletter.Expired.ReasonRefusal': 'Lý do từ chối đã hết hạn',
  'Mes.Newsletter.Expired.Title': 'Tiêu đề đã hết hạn',
  'Mes.Newsletter.AlreadyExist.Id': 'Mã định danh đã tồn tại',
  'Mes.Newsletter.AlreadyExist.CreationMode': 'Chế độ tạo đã tồn tại',
  'Mes.Newsletter.AlreadyExist.Duration': 'Thời lượng đã tồn tại',
  'Mes.Newsletter.AlreadyExist.Schedule': 'Lịch trình đã tồn tại',
  'Mes.Newsletter.AlreadyExist.Playlist': 'Danh sách phát đã tồn tại',
  'Mes.Newsletter.AlreadyExist.PlaylistId': 'Mã định danh danh sách phát đã tồn tại',
  'Mes.Newsletter.AlreadyExist.Template': 'Mẫu đã tồn tại',
  'Mes.Newsletter.AlreadyExist.TemplateId': 'Mã định danh mẫu đã tồn tại',
  'Mes.Newsletter.AlreadyExist.Media': 'Phương tiện đã tồn tại',
  'Mes.Newsletter.AlreadyExist.MediaId': 'Mã định danh phương tiện đã tồn tại',
  'Mes.Newsletter.AlreadyExist.PlayingStatus': 'Trạng thái phát đã tồn tại',
  'Mes.Newsletter.AlreadyExist.ApprovalStatus': 'Trạng thái phê duyệt đã tồn tại',
  'Mes.Newsletter.AlreadyExist.SourceType': 'Loại nguồn đã tồn tại',
  'Mes.Newsletter.AlreadyExist.Type': 'Loại đã tồn tại',
  'Mes.Newsletter.AlreadyExist.Field': 'Lĩnh vực đã tồn tại',
  'Mes.Newsletter.AlreadyExist.FieldId': 'Mã định danh lĩnh vực đã tồn tại',
  'Mes.Newsletter.AlreadyExist.Facility': 'Cơ sở đã tồn tại',
  'Mes.Newsletter.AlreadyExist.FacilityId': 'Mã định danh cơ sở đã tồn tại',
  'Mes.Newsletter.AlreadyExist.Organization': 'Tổ chức đã tồn tại',
  'Mes.Newsletter.AlreadyExist.OrganizationId': 'Mã định danh tổ chức đã tồn tại',
  'Mes.Newsletter.AlreadyExist.ReasonRefusal': 'Đã tồn tại Lý do từ chối',
  'Mes.Newsletter.AlreadyExist.Title': 'Tiêu đề đã tồn tại',
  'Mes.Newsletter.NotSpecialCharacter.Title': 'Tiêu đề không được chứa ký tự đặc biệt',
  'Mes.Newsletter.NotWhiteSpace.Title': 'Tiêu đề không được chứa khoảng trắng',
  'Mes.Newsletter.OverLength.Id': 'Mã định danh quá dài',
  'Mes.Newsletter.OverLength.ReasonRefusal': 'Lý do quá dài Từ chối',
  'Mes.Newsletter.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.Newsletter.Required.Id': 'Mã định danh bắt buộc',
  'Mes.Newsletter.Required.CreationMode': 'Chế độ tạo bắt buộc',
  'Mes.Newsletter.Required.Duration': 'Thời lượng bắt buộc',
  'Mes.Newsletter.Required.Schedule': 'Lịch trình bắt buộc',
  'Mes.Newsletter.Required.Playlist': 'Danh sách phát bắt buộc',
  'Mes.Newsletter.Required.PlaylistId': 'Mã định danh danh sách phát bắt buộc',
  'Mes.Newsletter.Required.Template': 'Mẫu bắt buộc',
  'Mes.Newsletter.Required.TemplateId': 'Mã định danh mẫu bắt buộc',
  'Mes.Newsletter.Required.Media': 'Phương tiện cần thiết',
  'Mes.Newsletter.Required.MediaId': 'Mã định danh phương tiện bắt buộc',
  'Mes.Newsletter.Required.PlayingStatus': 'Trạng thái phát bắt buộc',
  'Mes.Newsletter.Required.ApprovalStatus': 'Trạng thái phê duyệt bắt buộc',
  'Mes.Newsletter.Required.SourceType': 'Loại nguồn bắt buộc',
  'Mes.Newsletter.Required.Type': 'Loại bắt buộc',
  'Mes.Newsletter.Required.FieldId': 'Mã định danh lĩnh vực bắt buộc',
  'Mes.Newsletter.Required.FacilityId': 'Mã định danh cơ sở bắt buộc',
  'Mes.Newsletter.Required.OrganizationId': 'Mã định danh tổ chức bắt buộc',
  'Mes.Newsletter.Required.ReasonRefusal': 'Lý do từ chối bắt buộc',
  'Mes.Newsletter.Required.Title': 'Tiêu đề bắt buộc',
  'Mes.Newsletter.MustBeEmpty.Duration': 'Thời lượng phải trống',
  'Mes.Newsletter.Invalid': 'Bản tin không hợp lệ',
  'Mes.Newsletter.Invalid.CreationMode': 'Chế độ tạo không hợp lệ',
  'Mes.Newsletter.Invalid.Creator': 'Người tạo không hợp lệ',
  'Mes.Newsletter.Invalid.CreatorId': 'Id người sáng tạo không hợp lệ',
  'Mes.Newsletter.Invalid.Duration': 'Thời lượng không hợp lệ',
  'Mes.Newsletter.Invalid.Schedule': 'Lịch trình không hợp lệ',
  'Mes.Newsletter.Invalid.Playlist': 'Danh sách phát không hợp lệ',
  'Mes.Newsletter.Invalid.PlaylistId': 'Id danh sách phát không hợp lệ',
  'Mes.Newsletter.Invalid.Template': 'Mẫu không hợp lệ',
  'Mes.Newsletter.Invalid.TemplateId': 'Id mẫu không hợp lệ',
  'Mes.Newsletter.Invalid.Media': 'Phương tiện không hợp lệ',
  'Mes.Newsletter.Invalid.MediaId': 'Id phương tiện không hợp lệ',
  'Mes.Newsletter.Invalid.PlayingStatus': 'Trạng thái phát không hợp lệ',
  'Mes.Newsletter.Invalid.SourceType': 'Loại nguồn không hợp lệ',
  'Mes.Newsletter.Invalid.Type': 'Loại không hợp lệ',
  'Mes.Newsletter.Invalid.Field': 'Lĩnh vực không hợp lệ',
  'Mes.Newsletter.Invalid.FieldId': 'Mã định danh lĩnh vực không hợp lệ',
  'Mes.Newsletter.Invalid.Facility': 'Cơ sở không hợp lệ',
  'Mes.Newsletter.Invalid.FacilityId': 'Mã định danh cơ sở không hợp lệ',
  'Mes.Newsletter.Invalid.Organization': 'Tổ chức không hợp lệ',
  'Mes.Newsletter.Invalid.OrganizationId': 'Mã định danh tổ chức không hợp lệ',
  'Mes.Newsletter.Invalid.ReasonRefusal': 'Từ chối lý do không hợp lệ',
  'Mes.Newsletter.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.Newsletter.Repeated.Id': 'Mã định danh lặp lại',
  'Mes.Newsletter.Repeated.CreationMode': 'Chế độ tạo lặp lại',
  'Mes.Newsletter.Repeated.Creator': 'Người sáng tạo lặp lại',
  'Mes.Newsletter.Repeated.CreatorId': 'Mã định danh người tạo lặp lại',
  'Mes.Newsletter.Repeated.Duration': 'Thời lượng lặp lại',
  'Mes.Newsletter.Repeated.Schedule': 'Lịch trình lặp lại',
  'Mes.Newsletter.Repeated.Playlist': 'Danh sách phát lặp lại',
  'Mes.Newsletter.Repeated.PlaylistId': 'Mã định danh danh sách phát lặp lại',
  'Mes.Newsletter.Repeated.Template': 'Mẫu lặp lại',
  'Mes.Newsletter.Repeated.TemplateId': 'Mã định danh mẫu lặp lại',
  'Mes.Newsletter.Repeated.Media': 'Phương tiện lặp lại',
  'Mes.Newsletter.Repeated.MediaId': 'Mã định danh phương tiện lặp lại',
  'Mes.Newsletter.Repeated.PlayingStatus': 'Trạng thái phát lặp lại',
  'Mes.Newsletter.Repeated.ApprovalStatus': 'Trạng thái phê duyệt lặp lại',
  'Mes.Newsletter.Repeated.SourceType': 'Loại nguồn lặp lại',
  'Mes.Newsletter.Repeated.Type': 'Loại lặp lại',
  'Mes.Newsletter.Repeated.Field': 'Lĩnh vực lặp lại',
  'Mes.Newsletter.Repeated.FieldId': 'Mã định danh lĩnh vực lặp lại',
  'Mes.Newsletter.Repeated.Facility': 'Cơ sở lặp lại',
  'Mes.Newsletter.Repeated.FacilityId': 'Mã định danh cơ sở lặp lại',
  'Mes.Newsletter.Repeated.Organization': 'Tổ chức lặp lại',
  'Mes.Newsletter.Repeated.OrganizationId': 'Mã định danh tổ chức lặp lại',
  'Mes.Newsletter.Repeated.ReasonRefusal': 'Lý do từ chối lặp đi lặp lại',
  'Mes.Newsletter.Repeated.Title': 'Tiêu đề lặp lại',
  'Mes.Newsletter.NotFound.Id': 'Không tìm thấy Mã định danh',
  'Mes.Newsletter.NotFound.CreatorId': 'Không tìm thấy Mã định danh người sáng tạo',
  'Mes.Newsletter.NotFound.PlaylistId': 'Không tìm thấy Mã định danh danh sách phát',
  'Mes.Newsletter.NotFound.TemplateId': 'Không tìm thấy Mã định danh mẫu',
  'Mes.Newsletter.NotFound.MediaId': 'Mã định danh phương tiện không tìm thấy',
  'Mes.Newsletter.NotFound.Field': 'Lĩnh vực không tìm thấy',
  'Mes.Newsletter.NotFound.FieldId': 'Mã định danh lĩnh vực không tìm thấy',
  'Mes.Newsletter.NotFound.FacilityId': 'Không tìm thấy Mã định danh cơ sở',
  'Mes.Newsletter.NotFound.OrganizationId': 'Không tìm thấy Mã định danh tổ chức',
  'Mes.Newsletter.NotFound': 'Không tìm thấy bản tin',
  'Mes.Newsletter.WasUsed': 'Đã được sử dụng',
  'Mes.Newsletter.NotAllowed': 'Bản tin không được phép',
  'Mes.Newsletter.Blocked': 'Bản tin bị khóa',
  'Mes.Newsletter.Import.Failed': 'Nhập bản tin không thành công',
  'Mes.Newsletter.Import.Successfully': 'Nhập bản tin thành công',
  'Mes.Newsletter.Delete.Failed': 'Xóa bản tin không thành công',
  'Mes.Newsletter.Search.Failed': 'Tìm kiếm bản tin không thành công',
  'Mes.Newsletter.Search.Successfully': 'Tìm kiếm bản tin thành công',
  'Mes.Newsletter.ViewList.Failed': 'Xem danh sách Bản tin không thành công',
  'Mes.Newsletter.ViewList.Successfully': 'Xem danh sách bản tin thành công',
  'Mes.Newsletter.ViewDetail.Failed': 'Xem chi tiết Bản tin không thành công',
  'Mes.Newsletter.ViewDetail.Successfully': 'Xem chi tiết bản tin thành công',
  'Mes.Newsletter.Update.Failed': 'Cập nhật bản tin không thành công',
  'Mes.Newsletter.Update.Successfully': 'Cập nhật bản tin thành công',
  'Mes.Newsletter.Create.Failed': 'Tạo bản tin không thành công',
  'Mes.Newsletter.OverTime.FinalDate': 'Bản tin đã qua giờ phát',
  'Mes.Newsletter.Aprrove.Successfully': 'Phê duyệt bản tin thành công',
  'Mes.Newsletter.Aprrove.Failed': 'Phê duyệt bản tin không thành công',
  'Mes.Newsletter.Cancel.Failed': 'Hủy bản tin không thành công',
  'Mes.Newsletter.MustBeEmpty.FacilityIds': 'Mã định danh cơ sở phải bỏ trống',
  'Mes.Newsletter.Required.FacilityIds': 'Mã định danh cơ sở là bắt buộc',
  'Mes.Newsletter.MustBeEmpty.DeviceIds': 'Mã định danh thiết bị phải bỏ trống',
  'Mes.Newsletter.Required.DeviceIds': 'Mã định danh thiết bị là bắt buộc',
  'Mes.User.Required.TimezoneId': 'Múi giờ là bắt buộc',
  'Mes.User.Required.NotFound': 'Múi giờ không tồn tại',
  'Mes.User.Required.RedirectUri': 'Url chuyển hướng là bắt buộc',
  'Mes.User.OverLength.RedirectUri': 'Url chuyển hướng không vượt quá 255 kí tự',
  'Mes.User.OverLength.Code': 'Mã đăng nhập không vượt quá 255 kí tự',
  'Mes.User.Required.PhoneNumber': 'Số điện thoại là bắt buộc',
  'Mes.User.OverLength.IdentifierNumber': 'CMND/CCCD không vượt quá 50 kí tự',
  'Mes.User.Invalid.IdentifierNumber': 'CMND/CCCD không hợp lệ',
  'Mes.User.Repeated.Ids': 'Id người dùng không lặp lại',
  'Mes.User.Required.Ids': 'Id người dùng bắt buộc',
  'Mes.User.NotAllowed': 'Người dùng không được phép',
  'Mes.SsoResult.Empty.Id': 'Không tìm được id của người dùng trên sso',
  'Mes.SsoAuthentication.Failed': 'Đăng nhập Sso thất bại',
  'Mes.SsoResult.Empty.UserName': 'Không tìm được tên đăng nhập của người dùng trên sso',
  'Messages.SsoAuthentication.Required.AccessToken': 'Mã truy cập khi đăng nhập Sso là bắt buộc',
  'Mes.SsoAuthentication.Required.AccessToken': 'Mã truy cập khi đăng nhập Sso là bắt buộc',
  'Mes.SsoAuthentication.Invalid.Email': 'Sso xác nhận Email không hợp lệ',
  'Mes.SsoAuthentication.Invalid.PhoneNumber': 'Sso xác nhận số điện thoại không hợp lệ',
  'Mes.SsoAuthentication.Invalid.Username': 'Sso xác nhận tên đăng nhập không hợp lệ',
  'Mes.SsoAuthentication.Invalid.Groups':
    'Nhóm người dùng Sso không hợp lệ, vui lòng liên hệ quản trị viên của hệ thống để xử lí!',
  'Mes.SsoAuthentication.Invalid.ClientId':
    'ClientId không hợp lệ, vui lòng liên hệ quản trị viên của hệ thống để xử lí!',
  'Mes.SsoAuthentication.Invalid.ClientSecret':
    'ClientSecret không hợp lệ, vui lòng liên hệ quản trị viên của hệ thống để xử lí!',
  'Mes.SsoAuthentication.Invalid.GrantType':
    'GrantType không hợp lệ, vui lòng liên hệ quản trị viên của hệ thống để xử lí!',
  'Mes.UserMobileDevice.Required.DeviceId': 'Mã đăng kí thiết bị là bắt buộc',
  'Mes.UserMobileDevice.OverLength.DeviceId': 'Mã đăng kí thiết bị không vượt quá 255 kí tự',
  'Mes.Field.AlreadyExist.Code': 'Mã lĩnh vực đã tồn tại',
  'Mes.Field.Required.Ids': 'Mã định danh lĩnh vực bắt buộc',
  'Mes.Field.Repeated.Id': 'Mã định danh lặp lại',
  'Mes.Field.NotFound': 'Không tìm thấy lĩnh vực',
  'Mes.Field.Required.Name': 'Tên lĩnh vực là bắt buộc',
  'Mes.Field.OverLength.Name': 'Tên lĩnh vực quá dài',
  'Mes.Field.Required.LoginCode': 'Mã đăng nhập là bắt buộc',
  'Mes.Field.Invalid.LoginCode': 'Mã đăng nhập không hợp lệ',
  'Mes.Field.Required.Code': 'Mã lĩnh vực là bắt buộc',
  'Mes.Field.OverLength.Code': 'Mã lĩnh vực quá dài',
  'Mes.Field.NotWhiteSpace.Code': 'Mã lĩnh vực không được chứa khoảng trắng',
  'Mes.Field.NotSpecialCharacter.Code': 'Mã lĩnh vực không được chứa kí tự đặc biệt',
  'Mes.Field.Delete.Failed': 'Xóa lĩnh vực không thành công',
  'Mes.Field.Delete.Successfully': 'Xóa lĩnh vực thành công',
  'Mes.Field.Search.Failed': 'Tìm kiếm lĩnh vực không thành công',
  'Mes.Field.Search.Successfully': 'Tìm kiếm lĩnh vực thành công',
  'Mes.Field.ViewList.Failed': 'Xem danh sách lĩnh vực không thành công',
  'Mes.Field.ViewList.Successfully': 'Xem danh sách lĩnh vực thành công',
  'Mes.Field.ViewDetail.Failed': 'Xem chi tiết lĩnh vực không thành công',
  'Mes.Field.ViewDetail.Successfully': 'Xem chi tiết lĩnh vực thành công',
  'Mes.Field.Update.Failed': 'Cập nhật lĩnh vực không thành công',
  'Mes.Field.Update.Successfully': 'Cập nhật lĩnh vực thành công',
  'Mes.Field.Create.Failed': 'Tạo lĩnh vực không thành công',
  'Mes.Field.Create.Successfully': 'Tạo lĩnh vực thành công',
  'Mes.Field.WasUsed': 'Lĩnh vực đã được sử dụng',
  'Mes.Announcement.Required.Subject': 'Tiêu đề thông báo là bắt buộc',
  'Mes.Announcement.OverLength.Subject': 'Tiêu đề thông báo quá dài',
  'Mes.Announcement.Required.Content': 'Nội dung thông báo là bắt buộc',
  'Mes.Announcement.OverLength.Content': 'Nội dung thông báo quá dài',
  'Mes.Announcement.NotEnough.SendTime': 'Chưa đến thời gian gửi',
  'Mes.Announcement.Required.OrganizationIds': 'Mã định danh tổ chức là bắt buộc',
  'Mes.Announcement.Repeated.OrganizationIds': 'Mã định danh tổ chức bị lặp lại',
  'Mes.Announcement.Required.Channels': 'Kênh thông báo là bắt buộc',
  'Mes.Announcement.Repeated.Channels': 'Kênh thông báo bị lặp lại',
  'Mes.Announcement.Invalid.Channels': 'Kênh thông báo không hợp lệ',
  'Mes.Announcement.NotFound': 'Không tìm thấy thông báo',
  'Mes.Announcement.WasSent': 'Thông báo đã gửi',
  'Mes.Announcement.Delete.Failed': 'Xóa thông báo không thành công',
  'Mes.Announcement.Delete.Successfully': 'Xóa thông báo thành công',
  'Mes.Announcement.Search.Failed': 'Tìm kiếm thông báo không thành công',
  'Mes.Announcement.Search.Successfully': 'Tìm kiếm thông báo thành công',
  'Mes.Announcement.ViewList.Failed': 'Xem danh sách thông báo không thành công',
  'Mes.Announcement.ViewList.Successfully': 'Xem danh sách thông báo thành công',
  'Mes.Announcement.ViewDetail.Failed': 'Xem chi tiết thông báo không thành công',
  'Mes.Announcement.ViewDetail.Successfully': 'Xem chi tiết thông báo thành công',
  'Mes.Announcement.Update.Failed': 'Cập nhật thông báo không thành công',
  'Mes.Announcement.Update.Successfully': 'Cập nhật thông báo thành công',
  'Mes.Announcement.Create.Failed': 'Tạo thông báo không thành công',
  'Mes.Announcement.Create.Successfully': 'Tạo thông báo thành công',
  'Mes.Notification.Required.Ids': 'Mã định danh là bắt buộc',
  'Mes.Notification.Repeated.Ids': 'Mã định danh bị lặp lại',
  'Mes.Notification.Invalid.Group': 'Kênh thông báo không hợp lệ',
  'Mes.Notification.Required.Group': 'Mã định danh tổ chức là bắt buộc',
  'Mes.Notification.NotFound': 'Không tìm thấy thông báo',
  'Mes.Playlist.AlreadyExist.Code': 'Mã danh sách phát đã tồn tại',
  'Mes.Playlist.Required.Ids': 'Mã định danh danh sách phát bắt buộc',
  'Mes.Playlist.Repeated.Id': 'Mã định danh lặp lại',
  'Mes.Playlist.NotFound': 'Không tìm thấy danh sách phát',
  'Mes.Playlist.Required.Name': 'Tên danh sách phát là bắt buộc',
  'Mes.Playlist.OverLength.Name': 'Tên danh sách phát quá dài',
  'Mes.Playlist.Required.LoginCode': 'Mã đăng nhập là bắt buộc',
  'Mes.Playlist.Invalid.LoginCode': 'Mã đăng nhập không hợp lệ',
  'Mes.Playlist.Required.Code': 'Mã danh sách phát là bắt buộc',
  'Mes.Playlist.OverLength.Code': 'Mã danh sách phát quá dài',
  'Mes.Playlist.NotWhiteSpace.Code': 'Mã danh sách phát không được chứa khoảng trắng',
  'Mes.Playlist.NotSpecialCharacter.Code': 'Mã danh sách phát không được chứa kí tự đặc biệt',
  'Mes.Playlist.Delete.Failed': 'Xóa danh sách phát không thành công',
  'Mes.Playlist.Delete.Successfully': 'Xóa danh sách phát thành công',
  'Mes.Playlist.Search.Failed': 'Tìm kiếm danh sách phát không thành công',
  'Mes.Playlist.Search.Successfully': 'Tìm kiếm danh sách phát thành công',
  'Mes.Playlist.ViewList.Failed': 'Xem các danh sách phát không thành công',
  'Mes.Playlist.ViewList.Successfully': 'Xem các danh sách phát thành công',
  'Mes.Playlist.ViewDetail.Failed': 'Xem chi tiết danh sách phát không thành công',
  'Mes.Playlist.ViewDetail.Successfully': 'Xem chi tiết danh sách phát thành công',
  'Mes.Playlist.Update.Failed': 'Cập nhật danh sách phát không thành công',
  'Mes.Playlist.Update.Successfully': 'Cập nhật danh sách phát thành công',
  'Mes.Playlist.Create.Failed': 'Tạo danh sách phát không thành công',
  'Mes.Playlist.WasUsed': 'Danh sách phát đã được sử dụng',
  'Mes.Playlist.Required.FieldId': 'Mã định danh lĩnh vực là bắt buộc',
  'Mes.Playlist.Required.PlayMode': 'Chế độ phát là bắt buộc',
  'Mes.Playlist.Invalid.PlayMode': 'Chế độ phát không hợp lệ',
  'Mes.Playlist.OverLength.Description': 'Mô tả quá dài',
  'Mes.Playlist.Required.Sources': 'Nguồn thông tin danh sách phát là bắt buộc',
  'Mes.PlaylistResourceResource.Repeated.Order': 'Thứ tự sắp xếp bị lặp lại',
  'Mes.PlaylistResource.Required.Order': 'Thứ tự sắp xếp nguồn thông tin là bắt buộc',
  'Mes.PlaylistResource.OverLength.Order': 'Thứ tự sắp xếp nguồn thông tin quá lớn',
  'Mes.PlaylistResourceResource.NotEnough.Order': 'Thứ tự sắp xếp phải lớn hơn 0',
  'Mes.PlaylistResource.Required.Duration': 'Thời lượng nguồn thông tin là bắt buộc',
  'Mes.PlaylistResource.OverLength.Duration': 'Thời lượng nguồn thông tin quá lớn',
  'Mes.PlaylistResource.NotEnough.Duration': 'Thời lượng phải lớn hơn 0',
  'Mes.PlaylistResource.Required.SourceType': 'Loại nguồn thông tin là bắt buộc',
  'Mes.PlaylistResource.Invalid.SourceType': 'Loại nguồn thông tin không hợp lệ',
  'Mes.Facility.Required.ParentId': 'Mã định danh cơ sở trực thuộc bắt buộc',
  'Mes.Facility.Required.Code': 'Mã cơ sở bắt buộc',
  'Mes.Facility.OverLength.Code': 'Mã cơ sở quá dài',
  'Mes.Facility.NotWhiteSpace.Code': 'Mã cơ sở không được chứa khoảng trắng',
  'Mes.Facility.NotSpecialCharacter.Code': 'Mã cơ sở không được chứa kí tự đặc biệt',
  'Mes.Facility.AlreadyExist.Code': 'Mã cơ sở đã tồn tại',
  'Mes.Facility.Required.Name': 'Tên cơ sở bắt buộc',
  'Mes.Facility.OverLength.Name': 'Tên cơ sở quá dài',
  'Mes.Facility.Required.OperationStatus': 'Trạng thái hoạt động bắt buộc',
  'Mes.Facility.Invalid.OperationStatus': 'Trạng thái hoạt động không hợp lệ',
  'Mes.Facility.NotFound': 'Không tìm thấy cơ sở',
  'Mes.Facility.Import.Failed': 'Nhập cơ sở không thành công',
  'Mes.Facility.Import.Successfully': 'Nhập cơ sở thành công',
  'Mes.Facility.Delete.Failed': 'Xóa cơ sở không thành công',
  'Mes.Facility.Delete.Successfully': 'Xóa cơ sở thành công',
  'Mes.Facility.Search.Failed': 'Tìm kiếm cơ sở không thành công',
  'Mes.Facility.Search.Successfully': 'Tìm kiếm cơ sở thành công',
  'Mes.Facility.ViewList.Failed': 'Xem danh sách cơ sở không thành công',
  'Mes.Facility.ViewList.Successfully': 'Xem danh sách cơ sở thành công',
  'Mes.Facility.ViewDetail.Failed': 'Xem chi tiết cơ sở không thành công',
  'Mes.Facility.ViewDetail.Successfully': 'Xem chi tiết cơ sở thành công',
  'Mes.Facility.Update.Failed': 'Cập nhật cơ sở không thành công',
  'Mes.Facility.Update.Successfully': 'Cập nhật cơ sở thành công',
  'Mes.Facility.Create.Failed': 'Tạo cơ sở không thành công',
  'Mes.Facility.Create.Successfully': 'Tạo cơ sở thành công',
  'Mes.Facility.NotAllowed': 'Cơ sở không được phép',
  'Mes.Facility.StillExist.Medias': 'Cơ sở vẫn còn tồn tại Media',
  'Mes.Facility.Blocked': 'Cơ sở đã bị khóa',
  'Mes.Facility.Required.Ids': 'Mã định danh cơ sở bắt buộc',
  'Mes.Facility.Repeated.Id': 'Mã định danh lặp lại',
  'Mes.Supplier.Required.Ids': 'Mã định danh nhà cung cấp bắt buộc',
  'Mes.Supplier.Repeated.Id': 'Mã định danh lặp lại',
  'Mes.Supplier.NotFound': 'Không tìm thấy nhà cung cấp',
  'Mes.Supplier.Required.Name': 'Tên nhà cung cấp bắt buộc',
  'Mes.Supplier.OverLength.Name': 'Tên nhà cung cấp quá dài',
  'Mes.Supplier.Required.Code': 'Mã nhà cung cấp bắt buộc',
  'Mes.Supplier.OverLength.Code': 'Mã nhà cung cấp quá dài',
  'Mes.Supplier.NotWhiteSpace.Code': 'Mã nhà cung cấp không được chứa khoảng trắng',
  'Mes.Supplier.NotSpecialCharacter.Code': 'Mã nhà cung cấp không được chứa kí tự đặc biệt',
  'Mes.Supplier.AlreadyExist.Code': 'Mã nhà cung cấp đã tồn tại',
  'Mes.Supplier.Delete.Failed': 'Xóa nhà cung cấp không thành công',
  'Mes.Supplier.Delete.Successfully': 'Xóa nhà cung cấp thành công',
  'Mes.Supplier.Search.Failed': 'Tìm kiếm nhà cung cấp không thành công',
  'Mes.Supplier.Search.Successfully': 'Tìm kiếm nhà cung cấp thành công',
  'Mes.Supplier.ViewList.Failed': 'Xem danh sách nhà cung cấp không thành công',
  'Mes.Supplier.ViewList.Successfully': 'Xem danh sách nhà cung cấp thành công',
  'Mes.Supplier.ViewDetail.Failed': 'Xem chi tiết nhà cung cấp không thành công',
  'Mes.Supplier.ViewDetail.Successfully': 'Xem chi tiết nhà cung cấp thành công',
  'Mes.Supplier.Update.Failed': 'Cập nhật nhà cung cấp không thành công',
  'Mes.Supplier.Update.Successfully': 'Cập nhật nhà cung cấp thành công',
  'Mes.Supplier.Create.Failed': 'Tạo nhà cung cấp không thành công',
  'Mes.Supplier.Create.Successfully': 'Tạo nhà cung cấp thành công',
  'Mes.Template.Required.Ids': 'Mã định danh bản mẫu bắt buộc',
  'Mes.Template.Repeated.Id': 'Mã định danh lặp lại',
  'Mes.Template.NotFound': 'Không tìm thấy bản mẫu',
  'Mes.Template.Required.Name': 'Tên bản mẫu bắt buộc',
  'Mes.Template.OverLength.Name': 'Tên bản mẫu quá dài',
  'Mes.Template.Required.Code': 'Mã bản mẫu bắt buộc',
  'Mes.Template.OverLength.Code': 'Mã bản mẫu quá dài',
  'Mes.Template.NotWhiteSpace.Code': 'Mã bản mẫu không được chứa khoảng trắng',
  'Mes.Template.NotSpecialCharacter.Code': 'Mã bản mẫu không được chứa kí tự đặc biệt',
  'Mes.Template.AlreadyExist.Code': 'Mã bản mẫu đã tồn tại',
  'Mes.Template.Delete.Failed': 'Xóa bản mẫu không thành công',
  'Mes.Template.Delete.Successfully': 'Xóa bản mẫu thành công',
  'Mes.Template.Search.Failed': 'Tìm kiếm bản mẫu không thành công',
  'Mes.Template.Search.Successfully': 'Tìm kiếm bản mẫu thành công',
  'Mes.Template.ViewList.Failed': 'Xem danh sách bản mẫu không thành công',
  'Mes.Template.ViewList.Successfully': 'Xem danh sách bản mẫu thành công',
  'Mes.Template.ViewDetail.Failed': 'Xem chi tiết bản mẫu không thành công',
  'Mes.Template.ViewDetail.Successfully': 'Xem chi tiết bản mẫu thành công',
  'Mes.Template.Update.Failed': 'Cập nhật bản mẫu không thành công',
  'Mes.Template.Update.Successfully': 'Cập nhật bản mẫu thành công',
  'Mes.Template.Create.Failed': 'Tạo bản mẫu không thành công',
  'Mes.Template.Required.ResolutionWidth': 'Độ rộng độ phân giải là bắt buộc',
  'Mes.Template.NotEnough.ResolutionWidth': 'Độ rộng độ phân giải phải lớn hơn bằng 0',
  'Mes.Template.Required.Duration': 'Thời lượng là bắt buộc',
  'Mes.Template.NotEnough.Duration': 'Thời lượng phải lớn hơn 0',
  'Mes.Template.Invalid.Layout': 'Bố cục bản mẫu không hợp lệ',
  'Mes.Template.Required.Medias': 'Media là bắt buộc',
  'Mes.Organization.Required.Account': 'Tài khoản tổ chức là bắt buộc',
  'Mes.Organization.Required.TariffPackage': 'Gói cước tổ chức là bắt buộc',
  'Mes.Organization.Required.Code': 'Mã tổ chức là bắt buộc',
  'Mes.Organization.OverLength.Code': 'Mã tổ chức quá dài',
  'Mes.Organization.NotWhiteSpace.Code': 'Mã tổ chức không được chứa khoảng trắng',
  'Mes.Organization.NotSpecialCharacter.Code': 'Mã tổ chức không được chứa kí tự đặc biệt',
  'Mes.Organization.AlreadyExist.Code': 'Mã tổ chức đã tồn tại',
  'Mes.Organization.Required.Ids': 'Mã định danh tổ chức bắt buộc',
  'Mes.Organization.Repeated.Id': 'Mã định danh lặp lại',
  'Mes.Organization.Required.Name': 'Tên tổ chức là bắt buộc',
  'Mes.Organization.OverLength.Name': 'Tên tổ chức quá dài',
  'Mes.Organization.Required.IsAutoRenewal': 'Tự động gia hạn là bắt buộc',
  'Mes.Organization.NotFound': 'Không tìm thấy tổ chức',
  'Mes.Organization.Import.Failed': 'Nhập tổ chức không thành công',
  'Mes.Organization.Import.Successfully': 'Nhập tổ chức thành công',
  'Mes.Organization.Delete.Failed': 'Xóa tổ chức không thành công',
  'Mes.Organization.Delete.Successfully': 'Xóa tổ chức thành công',
  'Mes.Organization.Search.Failed': 'Tìm kiếm tổ chức không thành công',
  'Mes.Organization.Search.Successfully': 'Tìm kiếm tổ chức thành công',
  'Mes.Organization.ViewList.Failed': 'Xem danh sách tổ chức không thành công',
  'Mes.Organization.ViewList.Successfully': 'Xem danh sách tổ chức thành công',
  'Mes.Organization.ViewDetail.Failed': 'Xem chi tiết tổ chức không thành công',
  'Mes.Organization.ViewDetail.Successfully': 'Xem chi tiết tổ chức thành công',
  'Mes.Organization.Update.Failed': 'Cập nhật tổ chức không thành công',
  'Mes.Organization.Update.Successfully': 'Cập nhật tổ chức thành công',
  'Mes.Organization.Create.Failed': 'Tạo tổ chức không thành công',
  'Mes.Organization.Create.Successfully': 'Tạo tổ chức thành công',
  'Mes.Organization.Renewal.Failed': 'Gia hạn gói cước không thành công',
  'Mes.Organization.Renewal.Successfully': 'Gia hạn gói cước thành công',
  'Mes.Organization.UnUsed.Failed': 'Cập nhật gói cước không thành công',
  'Mes.Organization.UnUsed.Successfully': 'Cập nhật gói cước thành công',
  'Mes.Organization.WasUsed': 'Tổ chức đã được sử dụng',
  'Mes.Organization.Expired': 'Tổ chức đã hết hạn',
  'Mes.OrganizationSetting.Required.TimezoneId': 'Mã định danh múi giờ là bắt buộc',
  'Mes.Timezone.NotFound': 'Không tìm thấy múi giờ',
  'Mes.OrganizationSetting.Required.CreationMode': 'Chế độ tạo bản tin là bắt buộc',
  'Mes.OrganizationSetting.Invalid.CreationMode': 'Chế độ tạo bản tin không hợp lệ',
  'Mes.TariffPackage.IsNoLimit': 'Gói cước là không thời hạn',
  'Mes.TariffPackage.AlreadyExist.PackageUnUsed': 'Đã tồn tại gói cước chưa sử dụng',
  'Mes.TariffPackage.Required.Packages': 'Danh sách gói cước là bắt buộc',
  'Mes.TariffPackage.Required.Ids': 'Mã định danh gói cước bắt buộc',
  'Mes.TariffPackage.Repeated.Id': 'Mã định danh lặp lại',
  'Mes.TariffPackage.Required.StartTime': 'Thời gian bắt đầu là bắt buộc',
  'Mes.TariffPackage.Required.EndTime': 'Thời gian kết thúc là bắt buộc',
  'Mes.TariffPackage.Invalid.EndTime': 'Thời gian kết thúc không hợp lệ',
  'Mes.TariffPackage.Required.Capacity': 'Dung lượng bắt buộc',
  'Mes.TariffPackage.Invalid.Capacity': 'Dung lượng không hợp lệ',
  'Mes.TariffPackage.NotFound': 'Không tìm thấy gói cước',
  'Mes.TariffPackage.Import.Failed': 'Nhập gói cước không thành công',
  'Mes.TariffPackage.Import.Successfully': 'Nhập gói cước thành công',
  'Mes.TariffPackage.Delete.Failed': 'Xóa gói cước không thành công',
  'Mes.TariffPackage.Delete.Successfully': 'Xóa gói cước thành công',
  'Mes.TariffPackage.Search.Failed': 'Tìm kiếm gói cước không thành công',
  'Mes.TariffPackage.Search.Successfully': 'Tìm kiếm gói cước thành công',
  'Mes.TariffPackage.ViewList.Failed': 'Xem danh sách gói cước không thành công',
  'Mes.TariffPackage.ViewList.Successfully': 'Xem danh sách gói cước thành công',
  'Mes.TariffPackage.ViewDetail.Failed': 'Xem chi tiết gói cước không thành công',
  'Mes.TariffPackage.ViewDetail.Successfully': 'Xem chi tiết gói cước thành công',
  'Mes.TariffPackage.Update.Failed': 'Cập nhật gói cước không thành công',
  'Mes.TariffPackage.Update.Successfully': 'Cập nhật gói cước thành công',
  'Mes.TariffPackage.Create.Failed': 'Tạo gói cước không thành công',
  'Mes.TariffPackage.Create.Successfully': 'Tạo gói cước thành công',
  'Mes.TariffPackage.Renewal.Failed': 'Gia hạn gói cước không thành công',
  'Mes.TariffPackage.Renewal.Successfully': 'Gia hạn gói cước thành công',
  'Mes.TariffPackage.UnUsed.Failed': 'Cập nhật gói cước không thành công',
  'Mes.TariffPackage.UnUsed.Successfully': 'Cập nhật gói cước thành công',
  'Mes.TariffPackage.WasUsed': 'Gói cước đã được sử dụng',
  'Mes.TariffPackage.Expired': 'Gói cước đã hết hạn',
  'Mes.Package.Required.Name': 'Tên gói cước là bắt buộc',
  'Mes.Package.OverLength.Name': 'Tên gói cước quá dài',
  'Mes.Package.Required.Price': 'Giá gói cước là bắt buộc',
  'Mes.Package.Invalid.Price': 'Giá gói cước không hợp lệ',
  'Mes.Package.Required.PeriodByMonth': 'Thời hạn gói cước là bắt buộc',
  'Mes.Package.Invalid.PeriodByMonth': 'Thời hạn gói cước không hợp lệ',
  'Mes.Package.Required.Accessibility': 'Trạng thái công khai gói cước là bắt buộc',
  'Mes.Package.Invalid.Accessibility': 'Trạng thái công khai gói cước không hợp lệ',
  'Mes.Package.Required.BusinessStatus': 'Trạng thái kinh doanh cước là bắt buộc',
  'Mes.Package.Invalid.BusinessStatus': 'Trạng thái kinh doanh gói cước không hợp lệ',
  'Mes.Package.Required.ApplicableTimeFrom': 'Thời gian bắt đầu áp dụng đăng kí là bắt buộc',
  'Mes.Package.Required.ApplicableTimeTo': 'Thời gian kết thúc đăng kí là bắt buộc',
  'Mes.Package.Invalid.ApplicableTimeTo': 'Thời gian kết thúc đăng kí không hợp lệ',
  'Mes.Package.NotFound': 'Không tìm thấy gói cước',
  'Mes.Device.Required.Volume': 'Âm lượng là bắt buộc',
  'Mes.Device.Invalid.Volume': 'Âm lượng không hợp lệ',
  'Mes.Device.Required.Ids': 'Mã định danh thiết bị bắt buộc',
  'Mes.Device.Repeated.Id': 'Mã định danh lặp lại',
  'Mes.Device.NotFound': 'Không tìm thấy thiết bị',
  'Mes.Device.Required.LoginCode': 'Mã đăng nhập là bắt buộc',
  'Mes.Device.Invalid.LoginCode': 'Mã đăng nhập không hợp lệ',
  'Mes.Device.Required.Code': 'Mã thiết bị là bắt buộc',
  'Mes.Device.OverLength.Code': 'Mã thiết bị quá dài',
  'Mes.Device.NotWhiteSpace.Code': 'Mã thiết bị không được chứa khoảng trắng',
  'Mes.Device.NotSpecialCharacter.Code': 'Mã thiết bị không được chứa kí tự đặc biệt',
  'Mes.Device.AlreadyExist.Code': 'Mã thiết bị đã tồn tại',
  'Mes.Device.Invalid.Token': 'Token không hợp lệ',
  'Mes.Device.Expired.Token': 'Token đã hết hạn',
  'Mes.Newsletter.Required.StartTime': 'Thời gian bắt đầu là bắt buộc',
  'Mes.Newsletter.Required.EndTime': 'Thời gian kết thúc là bắt buộc',
  'Mes.Newsletter.Invalid.EndTime': 'Thời gian kết thúc không hợp lệ',
  'Mes.Device.Required.Name': 'Tên thiết bị là bắt buộc',
  'Mes.Device.OverLength.Name': 'Tên thiết bị quá dài',
  'Mes.Device.Required.SupplierId': 'Mã định danh nhà cung cấp là bắt buộc',
  'Mes.Device.Required.OperationStatus': 'Trạng thái hoạt động bắt buộc',
  'Mes.Device.Invalid.OperationStatus': 'Trạng thái hoạt động không hợp lệ',
  'Mes.Device.Required.SerialNumber': 'Sô seri SIM thiết bị là bắt buộc',
  'Mes.Device.OverLength.SerialNumber': 'Sô seri SIM thiết bị quá dài',
  'Mes.Device.Invalid.SubscriberNumber': 'Số thuê bao SIM không hợp lệ',
  'Mes.Device.Blocked': 'Thiết bị đã bị khóa',
  'Mes.Device.Delete.Failed': 'Xóa thiết bị không thành công',
  'Mes.Device.Delete.Successfully': 'Xóa thiết bị thành công',
  'Mes.Device.Search.Failed': 'Tìm kiếm thiết bị không thành công',
  'Mes.Device.Search.Successfully': 'Tìm kiếm thiết bị thành công',
  'Mes.Device.ViewList.Failed': 'Xem danh sách thiết bị không thành công',
  'Mes.Device.ViewList.Successfully': 'Xem danh sách thiết bị thành công',
  'Mes.Device.ViewDetail.Failed': 'Xem chi tiết thiết bị không thành công',
  'Mes.Device.ViewDetail.Successfully': 'Xem chi tiết thiết bị thành công',
  'Mes.Device.Update.Failed': 'Cập nhật thiết bị không thành công',
  'Mes.Device.Update.Successfully': 'Cập nhật thiết bị thành công',
  'Mes.Device.Create.Failed': 'Tạo thiết bị không thành công',
  'Mes.Device.Create.Successfully': 'Tạo thiết bị thành công',
  'Mes.Device.Invalid.Id': 'Mã định danh thiết bị không hợp lệ',
  'Mes.Device.Login.Failed': 'Đăng nhập thiết bị không thành công',
  'Mes.Device.Login.Successfully': 'Đăng nhập thiết bị thành công',
  'Mes.DeviceRefeshToken.Invalid.Token': 'Token không hợp lệ',
  'Mes.DeviceRefeshToken.Expired.Token': 'Token đã hết hạn',
  'Mes.Device.Reboot.Failed': 'Khởi động lại thiết bị không thành công',
  'Mes.Device.Reboot.Successfully': 'Khởi động lại thiết bị thành công',
  'Mes.Device.AdjustVolume.Failed': 'Điều chỉnh âm lượng thiết bị không thành công',
  'Mes.Device.AdjustVolume.Successfully': 'Điều chỉnh âm lượng thiết bị thành công',
  'Mes.ActivityLogs.Required.Volume': 'Âm lượng là bắt buộc',
  'Mes.ActivityLogs.Invalid.Volume': 'Âm lượng không hợp lệ',
  'Mes.ActivityLogs.Required.NewsletterId': 'Mã định danh bản tin là bắt buộc',
  'Mes.ActivityLogs.MustBeEmpty.NewsletterId': 'Mã định danh bản tin phải để trống',
  'Mes.ActivityLogs.Required.SessionId': 'Mã định danh phiên là bắt buộc',
  'Mes.ActivityLogs.Delete.Failed': 'Xóa nhật kí hoạt động không thành công',
  'Mes.ActivityLogs.Delete.Successfully': 'Xóa nhật kí hoạt động thành công',
  'Mes.ActivityLogs.Search.Failed': 'Tìm kiếm nhật kí hoạt động không thành công',
  'Mes.ActivityLogs.Search.Successfully': 'Tìm kiếm nhật kí hoạt động thành công',
  'Mes.ActivityLogs.ViewList.Failed': 'Xem danh sách nhật kí hoạt động không thành công',
  'Mes.ActivityLogs.ViewList.Successfully': 'Xem danh sách nhật kí hoạt động thành công',
  'Mes.ActivityLogs.ViewDetail.Failed': 'Xem chi tiết nhật kí hoạt động không thành công',
  'Mes.ActivityLogs.ViewDetail.Successfully': 'Xem chi tiết nhật kí hoạt động thành công',
  'Mes.ActivityLogs.Update.Failed': 'Cập nhật nhật kí hoạt động không thành công',
  'Mes.ActivityLogs.Update.Successfully': 'Cập nhật nhật kí hoạt động thành công',
  'Mes.ActivityLogs.Create.Failed': 'Tạo nhật kí hoạt động không thành công',
  'Mes.ActivityLogs.Create.Successfully': 'Tạo nhật kí hoạt động thành công',
  'Mes.Device.Repeated.Code': 'Mã định danh đã tồn tại',
  'Mes.Newsletter.Playback.Successfully': 'Thiết lập thành công',
  'Mes.Media.NotFound': 'Không tìm thấy media',
  'Mes.Organization.Renewal': 'Gia hạn gói cước thành công',
  'Mes.Newsletter.Approve.Successfully': 'Phê duyệt bản tin thành công',
  'Mes.Media.Sharing.Successfully': 'Chia sẻ media thành công',
  'Mes.MediaShare.Reception': 'Tiếp nhận media được chia sẻ thành công',
  'Mes.Media.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.Media.Update.Successfully': 'Cập nhật media thành công',
  'Mes.Media.Approve.Successfully': 'Phê duyệt media thành công',
  'Mes.Newsletter.Refuse.Successfully': 'Từ chối duyệt bản tin thành công',
  'Mes.Media.Refuse.Successfully': 'Từ chối duyệt media thành công',
  'Mes.Organization.Truncate.Successfully': 'Hủy tổ chức thành công',
  'Mes.TariffPackage.Invalid.EffectiveTime': 'Thời gian hiệu lực không hợp lệ',
  'Mes.Media.Approved': 'Media đã duyệt',
  'Mes.Media.WasUsed': 'Media đã sử dụng',
  'Mes.Media.Delete.Successfully': 'Xoá media thành công',
  'Mes.Media.Invalid.ResourceUrl': 'Đường dẫn không hợp lệ',
  'Mes.Media.MustBeEmpty.Content': 'Tệp tin phải để trống',
  'Mes.Media.InvalidFormat.ResourceUrl': 'Đường dẫn không đúng định dạng',
  'Mes.Media.Create.Failed': 'Tạo media thất bại',
  'Mes.Media.Required.ResourceUrl': 'Đường dẫn file là bắt buộc',
  'Mes.Packet.NotFound': 'Không tìm thấy gói cước',
  'Mes.Media.Crashed.ResourceUrl': 'Đường dẫn không đúng',
  'Mes.User.NotFound.Email': 'Không tìm thấy email của người dùng',
  'Mes.TariffPackage.NotAllowed': 'Gói cước này không được phép gia hạn',
  'Per.Users.Create': 'Thêm user',
  'Per.Users.Delete': 'Xoá user',
  'Per.Users.Edit': 'Sửa user',
  'Per.Users.Export': 'Xuất user',
  'Per.Users.View': 'Xem user',
  'Mes.Users.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Roles.Add.Successfully': 'Thêm vai trò thành công',
  'Mes.Organization.NotEnough.Capacity': 'Không đủ dung lượng',
  'Mes.DeviceAuth.Invalid.Code': 'Mã thiết bị không hợp lệ',
  'Mes.Device.VerifyLoginCode.Successfully': 'Xác thực thiết bị thành công',
  'Mes.Device.ConfirmLoginCode.Successfully': 'Xác thực đăng nhập thành công',
  'Mes.DeviceGroup.AlreadyExist.Name': 'Tên nhóm thiết bị đã tồn tại',
  'Mes.DeviceGroup.Required.Name': 'Tên nhóm thiết bị là bắt buộc',
  'Mes.DeviceGroup.OverLength.Name': 'Tên nhóm thiết bị không vượt quá 255 kí tự',
  'Mes.DeviceGroup.NotFound': 'Nhóm thiết bị không tồn tại',
  'Mes.DeviceGroup.Create.Successfully': 'Tạo nhóm thiết bị thành công',
  'Mes.DeviceGroup.Update.Successfully': 'Cập nhật nhóm thiết bị thành công',
  'Mes.DeviceGroup.Delete.Successfully': 'Xóa nhóm thiết bị thành công',
  'Mes.OrganizationSubscription.Cancel.Successfully': 'Hủy gia hạn thành công',
  'Mes.OrganizationSubscription.Renew.Successfully': 'Gia hạn thành công',
};
