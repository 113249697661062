import { PresetColorType } from 'antd/lib/_util/colors';

import { languageEN, languageVN } from '@assets/images';
import ISelect from '@core/select';

export const LANGUAGE: ISelect<string>[] = [
  { value: 'vi', label: 'VIE', icon: languageVN },
  { value: 'en', label: 'ENG', icon: languageEN },
];

export const allSelect: ISelect = { label: 'common.all', value: undefined };

const CONFIG = {
  API_BASE_URL: process.env.API_BASE_URL || '',
  APP_NAME: process.env.APP_NAME || 'CMS-SSO',
  LOGIN_PAGE: process.env.SSO_PAGE || 'https://sta-asmtc-sso.dev.altasoftware.vn/',
  PROFILE_PAGE: `${
    process.env.SSO_PAGE || 'https://sta-asmtc-sso.dev.altasoftware.vn/'
  }profile/profile-page`,
  CLIENT_ID: process.env.CLIENT_ID || 'a60c3c46-d27b-4b8b-b207-dad739dcb9b6',
  API_KEY_GG_MAP: process.env.API_KEY_GG_MAP || 'AIzaSyCUl_Bfc4ztoB9wrIOHST6iJtAORie66Hc',
  URL_TARIFF_PAGE: process.env.URL_TARIFF_PAGE || 'https://asmtc-sso.dev.altasoftware.vn/',
  API_MESHCENTRAL: process.env.API_MESHCENTRAL || 'https://meshcentral.dev.altasoftware.vn',
  SCOPE: process.env.SCOPE || 'user.profile user.idcard.Read',
  TINY_KEY: process.env.TINY_KEY || 'p0wepyb5vhie1fq4i2f3ok37mdvx2dncg3m1262f1gvkdnqz',
  DEFAULT_LANG: process.env.DEFAULT_LANG || 'vi',
  MTC_SOLUTION: process.env.MTC_SOLUTION || 'https://mtcsolution.dev.altasoftware.vn',
  MOBILE_WIDTH: Number(process.env.MOBILE_WIDTH) || 600,
  TABLET_WIDTH: Number(process.env.TABLET_WIDTH) || 1024,
  IOS_APP_URL:
    process.env.IOS_APP_URL ||
    'https://apps.apple.com/us/app/multimedia-terminal-controller/id6502227651',
  ANDROID_APP_URL:
    process.env.ANDROID_APP_URL ||
    'https://play.google.com/store/apps/details?id=com.mtc_cms_mobile.pro&pli=1',
  QUERY_EXTEND: process.env.QUERY_EXTEND || 'allowExtend',
  QUERY_EXTEND_VALUE: process.env.QUERY_EXTEND_VALUE || 'accept',
  ROUTER_HISTORY: process.env.ROUTER_HISTORY || 'routerHistoryMTC',
};

export const colors: PresetColorType[] = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime',
];

export default CONFIG;
