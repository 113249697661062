import React from 'react';

import { PaginationEntity } from '@core/pagination/entity';
import httpRepository from '@core/repository/http';
import { OptionEntity, TableEntity } from '@core/table';
import { convertDataResponse } from '@helper/functions';
import NewsEntity from '@modules/newsManagement/news/newsEntity';

import MediasEntity from './mediasEntity';

// API GET
export const getListMedias = (
  pagination: PaginationEntity,
  options: OptionEntity,
  organizationId?: string,
) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: organizationId != null ? `/api/Facilities/${organizationId}/Medias` : '/api/Medias',
    showSuccess: false,
    showError: false,
    params,
    convert: res => convertDataResponse(res, MediasEntity.createListMedias),
  });
};
//and get detail
export const getDetailMedias = (id: string, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/Medias/${id}`
        : '/api/Medias/' + id,
    showSuccess: false,
    showError: false,
    convert: res => {
      return new MediasEntity(res);
    },
  });
};

//API ADD
export const createMedias = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Medias',
    method: 'post',
    payload,
    config: {
      isFormData: true,
    },
  });
};

//API EDIT/UPDATE
export const updateMedias = (id: string, payload: any) => {
  return httpRepository.execute({
    path: '/api/Medias/' + id,
    method: 'put',
    payload,
    config: {
      isFormData: true,
    },
  });
};

//API DELETE
export const deleteMediasMany = (ids: React.Key[]) => {
  return httpRepository.execute({
    path: '/api/Medias/DeleteMany',
    method: 'post',
    payload: {
      ids,
    },
  });
};

export const approveMediasMany = (ids: React.Key[]) => {
  return httpRepository.execute({
    path: '/api/Medias/ApproveMany',
    method: 'post',
    payload: {
      ids,
    },
  });
};

export const refuseApprovalMedia = (id: string, payload: any) => {
  return httpRepository.execute({
    path: `/api/Medias/${id}/RefuseApproval`,
    method: 'post',
    payload,
  });
};

// API GET
export const getListMediasShare = (
  id: string | undefined,
  pagination: PaginationEntity,
  options: OptionEntity,
) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: `/api/Medias/${id}/Shares`,
    showSuccess: false,
    showError: false,
    params,
    convert: res => convertDataResponse(res),
  });
};

//API DELETE
export const sharesMedias = (id: string, payload: any) => {
  return httpRepository.execute({
    path: `/api/Medias/${id}/Shares`,
    method: 'post',
    payload,
  });
};

//facilitys
export const getListFacilitiesMedias = (
  id,
  pagination: PaginationEntity,
  options: OptionEntity,
) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: `/api/Facilities/${id}/Medias`,
    showSuccess: false,
    showError: false,
    params,
    convert: res => convertDataResponse(res, MediasEntity.createListMedias),
  });
};

//and get detail
export const getDetailFacilitiesMedias = (id: string | undefined, mediaId: string | undefined) => {
  return httpRepository.execute({
    path: `/api/Facilities/${id}/Medias/${mediaId}`,
    showSuccess: false,
    showError: false,
    convert: res => {
      return new MediasEntity(res);
    },
  });
};

//API ADD
export const createFacilitiesMedias = (id, payload: any) => {
  return httpRepository.execute({
    path: `/api/Facilities/${id}/Medias`,
    method: 'post',
    payload,
    config: {
      isFormData: true,
    },
  });
};

//API EDIT/UPDATE
export const updateFacilitiesMedias = (id: string, mediaId: string, payload: any) => {
  return httpRepository.execute({
    path: `/api/Facilities/${id}/Medias/${mediaId}`,
    method: 'put',
    payload,
    config: {
      isFormData: true,
    },
  });
};

//API DELETE
export const deleteFacilitiesMediasMany = (id, ids: React.Key[]) => {
  return httpRepository.execute({
    path: `/api/Facilities/${id}/Medias/DeleteMany`,
    method: 'post',
    payload: {
      ids,
    },
  });
};

export const approveFacilitiesMediasMany = (id, ids: React.Key[]) => {
  return httpRepository.execute({
    path: `/api/Facilities/${id}/Medias/ApproveMany`,
    method: 'post',
    payload: {
      ids,
    },
  });
};

export const refuseApprovalFacilitiesMedia = (id: string, mediaId: string, payload: any) => {
  return httpRepository.execute({
    path: `/api/Facilities/${id}/Medias/${mediaId}/RefuseApproval`,
    method: 'post',
    payload,
  });
};

export const getListFacilitiesMediasShare = (
  id: string | undefined,
  mediaId: string | undefined,
  pagination: PaginationEntity,
  options: OptionEntity,
) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: `/api/Facilities/${id}/Medias/${mediaId}/Shares`,
    showSuccess: false,
    showError: false,
    params,
    convert: res => convertDataResponse(res),
  });
};

export const sharesFacilitiesMedias = (id: string, mediaId: string, payload: any) => {
  return httpRepository.execute({
    path: `/api/Facilities/${id}/Medias/${mediaId}/Shares`,
    method: 'post',
    payload,
  });
};

// API GET
export const getListRelatedNewsletters = (
  id: string,
  pagination: PaginationEntity,
  options: OptionEntity,
) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: `/api/Medias/${id}/RelatedNewsletters`,
    showSuccess: false,
    showError: false,
    params,
    convert: res => convertDataResponse(res, NewsEntity.createListNews),
  });
};

export const getListFacilitiesRelatedNewsletters = (
  facilityId: string | undefined,
  mediaId: string | undefined,
  pagination: PaginationEntity,
  options: OptionEntity,
) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: `/api/Facilities/${facilityId}/Medias/${mediaId}/RelatedNewsletters`,
    showSuccess: false,
    showError: false,
    params,
    convert: res => convertDataResponse(res, NewsEntity.createListNews),
  });
};
