import { useIntl } from 'react-intl';

import notificationsEn from '@locale/enUS/notifications';
import { Locale } from '@locale/index';
import notificationsVi from '@locale/viVN/notifications';

export const useAltaIntl = () => {
  const intl = useIntl();
  const language: keyof Locale = intl.locale as keyof Locale;

  const formatMessage = (key: string): string => {
    return intl.formatMessage({ id: key });
  };
  const formatCurrency = (v: number) => {
    return intl.formatNumber(v, {
      style: 'currency',
      currency: 'vnd',
      unitDisplay: 'narrow',
      notation: 'standard',
    });
  };

  const translateString = (title: string) => {
    const _notifications = { en: notificationsEn, vi: notificationsVi };
    const regex = new RegExp(Object.keys(_notifications[language]).join('|'), 'g');
    return title.replace(regex, match => {
      return _notifications[language][match];
    });
  };

  return {
    intl,
    formatMessage,
    formatCurrency,
    translateString,
  };
};
