import React from 'react';

import { IRouter } from '@routers/interface';

export const routerDeviceDashboardOrganizations: IRouter = {
  path: '/dashboard/organizations/:organizationId/device-dashboard',
  loader: React.lazy(() => import('./index')),
  activePath: '/dashboard',
  exact: true,
  name: 'Dashboard', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'dashboard.device.router.nameBreadcrumb',
  generatePath(organizationId) {
    return `/dashboard/organizations/${organizationId}/device-dashboard`;
  },
};

export const routerDeviceDashboard: IRouter = {
  path: '/dashboard/device-dashboard',
  loader: React.lazy(() => import('./index')),
  activePath: '/dashboard',
  exact: true,
  name: 'Dashboard', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'dashboard.device.router.nameBreadcrumb',
};
