export default {
  'dashboard.router.manager': 'Dashboard',
  'dashboard.summary.organizations': 'Accounts',
  'dashboard.summary.organizations.expired': 'Expired Accounts',
  'dashboard.summary.organizations.due': 'Active Accounts',
  'dashboard.summary.capacityUsed.text': 'In Used Storage',
  'dashboard.summary.capacity.text': 'Total Registered',
  'dashboard.filter.organizations': 'Accounts',
  'dashboard.filter.status': 'Broadcast Status',
  'dashboard.filter.status.active': 'Active',
  'dashboard.filter.status.notActive': 'Inactive',
  'dashboard.marker.device.name': 'Device Name',
  'dashboard.marker.organizations': 'Account',
  'dashboard.marker.specificAddress': 'Installation Address',
  'dashboard.marker.latitude': 'Latitude',
  'dashboard.marker.longitude': 'Longitude',
  'dashboard.marker.status': 'Broadcast Status',
  'dashboard.marker.device.info': 'Device Information',
  'dashboard.marker.devices.operationStatus.id':
    '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'dashboard.summary.facilities': 'Total Locations',
  'dashboard.summary.totalDevice': 'Devices',
  'dashboard.summary.totalNewsletter': 'Total Broadcast',
  'dashboard.summary.capacity': 'Total Capacity',
  'dashboard.summary.totalDevice.label': 'Maximum',
  'dashboard.summary.totalDevice.valueDesc': 'device(s)',
  'dashboard.device.title': 'Device Dashboard',
  'dashboard.device.name': 'Device',
  'dashboard.device.facility.name': 'Facility',
  'dashboard.device.deviceGroups': 'Device Group(s)',
  'dashboard.device.connectionStatus': 'Connection Status',
  'dashboard.device.connectionStatus.connecting': 'Connected',
  'dashboard.device.connectionStatus.notConnectedYet': 'Disconnected',
  'dashboard.device.playingStatus': 'Broadcast Status',
  'dashboard.device.playingStatus.playing': 'Broadcasting',
  'dashboard.device.playingStatus.notScheduledYet': 'Not Broadcasting',
  'dashboard.device.playingStatus.undefined': 'Offline Check',
  'dashboard.device.operationStatus': 'Operational Status',
  'dashboard.device.operationStatus.active': 'Active',
  'dashboard.device.operationStatus.deactive': 'Inactive',
  'dashboard.device.information': 'Information',
  'dashboard.device.filter.connectionStatus': 'Connection Status',
  'dashboard.device.filter.connectionStatus.connecting': 'Connected',
  'dashboard.device.filter.connectionStatus.notConnectedYet': 'Disconnected',
  'dashboard.device.filter.playingStatus': 'Broadcast Status',
  'dashboard.device.filter.playingStatus.playing': 'Broadcasting',
  'dashboard.device.filter.playingStatus.notScheduledYet': 'Not Broadcasting',
  'dashboard.device.filter.playingStatus.undefined': 'Offline Check',
  'dashboard.device.filter.operationStatus': 'Operational Status',
  'dashboard.device.filter.operationStatus.active': 'Active',
  'dashboard.device.filter.operationStatus.deactive': 'Inactive',
  'dashboard.device.chart.title.connectionStatus': 'Connection Rate',
  'dashboard.device.chart.title.playingStatus': 'Broadcast Status',
  'dashboard.device.chart.title.operationStatus': 'Operation Rate',
  'dashboard.device.chart.title': 'Device List',
  'dashboard.device.export': 'Export excel file',
  'dashboard.marker.device.deviceGroup': 'Device group(s)',
  'dashboard.device.router.nameBreadcrumb': 'Device Dashboard',
  'dashboard.device.connectionStatus.param':
    '{status,select, 1 {Connected} 2 {Disconnected} other {Undefined}}',
  'dashboard.device.playingStatus.param':
    '{status,select, 1 {Broadcasting} 2 {Not Broadcasting} 3 {Offline Check} other {Unknown}}',
  'dashboard.device.operationStatus.param':
    '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'dashboard.device.filter.deviceGroup': 'Device Group(s)',
  'dashboard.device.filter.deviceGroup.placeholder': 'Please select device group(s)',
  'dashboard.device.filter.facility': 'Location',
  'dashboard.device.filter.facility.placeholder': 'Please select Location',
  'dashboard.device.chart.head.title': 'Total Devices',
  'dashboard.device.chart.device.unit': 'device(s)',
  'dashboard.device.chart.empty.des': 'No Data Available',
};
